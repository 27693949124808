import {
  CUSTOMER_ID,
  EN,
  GA_EVENT_NAME,
  GA_EVENT_TAG,
  LANGUAGE,
  PREFIX,
  USER,
  USER_AUTH,
  GA_EVENT_BIZ,
  SAAS_TENANT_ID,
  SAAS_TENANT_INFO
} from "../config/hosts/index";
import ReactGA from "react-ga4";
import {
  getBrowserDeviceInfo,
  getPathNameWithoutLang,
  getTenantStorageKey,
  handleEventPathname,
  isClient,
  systemDetection
} from "./index";
import type { GA_PARAMS } from "@aspen/model/index";
import { ADGM_BRAND } from "@aspen/model/index";

const CURRENT_PAGE_BY_LISTENED = "current_page_by_listened";

const getCurrentPageByListened = () => {
  return isClient && localStorage.getItem(CURRENT_PAGE_BY_LISTENED);
};
// @ts-ignore
const getEvent = (event) => {
  event = event || window.event;
  if (!event) {
    return event;
  }
  if (!event.target) {
    event.target = event.srcElement;
  }
  if (!event.currentTarget) {
    event.currentTarget = event.srcElement;
  }
  return event;
};

const getEventListenerMethod = () => {
  let addMethod = "addEventListener",
    removeMethod = "removeEventListener",
    prefix = "";
  if (!window.addEventListener) {
    addMethod = "attachEvent";
    removeMethod = "detachEvent";
    prefix = "on";
  }
  return {
    addMethod,
    removeMethod,
    prefix
  };
};

// @ts-ignore
const filterTraceCondition = (eventFix, currentPage) => {
  const currentLocationPathName = currentPage; // 当前执行点击的页面
  let locationIsMatched = eventFix?.view?.history.state.url !== `/${currentLocationPathName}`; // 证明页面已经跳转
  let isHrefNode = eventFix?.target?.nodeName === "A"; // exist && A
  return locationIsMatched || isHrefNode;
};

/**
 * 页面访问事件
 * @param page
 */
export function commonPageViewLog(page: string) {
  const _page = `${PREFIX}_${page}`;
  ReactGA.send({ hitType: "pageview", _page });
}

interface ReportEventParams {
  moduleName?: string;
  detailParams?: GA_PARAMS;
  pathname?: string;
  joinedTag?: string; // 点击或modal弹窗事件，事件名中间的连接字符串
}

/**
 * 通过ReactGA.event上报，通用上报准确事件
 * @param moduleName sting
 * @param detailParams Record<string, string | number | boolean>
 * @param pathname string
 * @param joinedTag string  代表上报事件类型即 事件名中间需要拼接的部分，ck ==> click; open ==> modal, detault = ck
 */
export function reportEvent({
  moduleName,
  detailParams = {},
  pathname,
  joinedTag = GA_EVENT_TAG.Click
}: ReportEventParams) {
  function prefixEventName(handledPath: {
    routePath: string;
    pathname: string;
    param: Record<string, string | number | boolean>;
  }) {
    const system = systemDetection();
    let name = `${PREFIX}_${handledPath.pathname}`;
    if (system === ADGM_BRAND) {
      name = `ad_${name}`;
    }
    return name;
  }
  const currentPage = pathname || (isClient && getPathNameWithoutLang(location.pathname));
  const customerId = isClient && localStorage.getItem(CUSTOMER_ID);
  const user = isClient && localStorage.getItem(USER);
  const role = isClient && localStorage.getItem(USER_AUTH);
  const locale = isClient ? window.localStorage.getItem(LANGUAGE) || EN : EN;
  // 获取设备信息
  const deviceInfo = getBrowserDeviceInfo();
  // @ts-ignore
  const handledPath = handleEventPathname(currentPage);
  let name = prefixEventName(handledPath);
  if (moduleName) {
    if (joinedTag) name = `${name}_${joinedTag}`;
    name = `${name}_${moduleName}`;
  }
  const paramsObj = { ...detailParams };

  // saas租户信息如果存在，则加进上报参数中
  const tenantId =
    typeof window != "undefined" && localStorage.getItem(getTenantStorageKey(SAAS_TENANT_ID));
  if (tenantId) {
    paramsObj.tenantId = tenantId;
  }
  const tenantInfo =
    typeof window != "undefined" && localStorage.getItem(getTenantStorageKey(SAAS_TENANT_INFO));
  if (tenantInfo) {
    try {
      const info = JSON.parse(tenantInfo);
      paramsObj.tenantName = info.name;
      paramsObj.tenantDomain = info.domain;
      paramsObj.tenantDomainName = info.domainName;
      paramsObj.tenantCompanyFullName = info.companyFullName;
      paramsObj.tenantCompanyShortName = info.companyShortName;
      paramsObj.tenantContactName = info.contactName;
      paramsObj.tenantAccountCount = info.accountCount;
      paramsObj.tenantEmail = info.email;
      paramsObj.tenantPackageId = info.packageId;
      paramsObj.tenantStatus = info.status;
      paramsObj.tenantSupportEmail = info.supportEmail;
    } catch (err) {
      console.log("tenantInfo parse error", err);
    }
  }
  paramsObj.businessType = getBusinessTypeWithEvent(name, moduleName, handledPath.routePath);
  if (handledPath.param.param_id) {
    const _id = (handledPath.param.param_id ?? "")?.toString();
    paramsObj.id = _id;
    // 如果id会自动转成科学计数法，则手动拼接"id="的前缀
    if (!isNaN(Number(_id)) && Number(_id).toString().includes("e")) {
      paramsObj.id = `id=${_id}`;
    }
  }
  let params = {
    userId: (customerId ?? "")?.toString(),
    customerName: user,
    customerRole: role,
    pageView: currentPage,
    language: locale,
    timestamp: new Date().getTime(),
    moduleName,
    ...paramsObj
  };
  // @ts-ignore
  if (["mobile", "tablet"].includes(deviceInfo.deviceType)) {
    params = { ...params, ...deviceInfo };
  }
  if (process.env.NODE_ENV != "production") {
    params = { ...params, ...{ debug_mode: true } };
  }
  ReactGA.event(name, params);
}
// @ts-ignore
function getBusinessTypeWithEvent(eventName, moduleName, pathName: string): string {
  // @ts-ignore
  let biz = GA_EVENT_BIZ[eventName];
  if (biz) {
    return biz;
  }
  if (moduleName === GA_EVENT_NAME.common.navigation) {
    return "General";
  }
  return "";
}
/**
 *
 * @param customerName
 * @param currentPage
 * @param type
 */
// @ts-ignore
export function customerLogEvent(customerName, currentPage = location.pathname, type = "click") {
  ReactGA.event({
    category: `${currentPage}_${customerName}`,
    action: `${customerName}_click`,
    label: "Click"
  });
}

/**
 * 通过dom属性来判断哪些点击是跳转操作。
 * @param args
 */
function enhanceListenCb(...args: any[]) {
  const eventFix = getEvent(args[0]);
  // const trackerValue = filterTraceCondition(eventFix, getCurrentPageByListened());
  // if (trackerValue) {
  //   commonLogEvent(getCurrentPageByListened());
  // }
  // 点击上报事件 data-id ,传入格式必须为xxx-xxx, 模块名-详细参数
  const eventId = eventFix.target.getAttribute("data-id");
  if (eventId) {
    reportEvent(eventId);
  }
}

class TrackerReport {
  static instance = null;
  page: string;
  static getInstance(page = "") {
    if (!this.instance) {
      // @ts-ignore
      this.instance = new TrackerReport(page);
    }
    return this.instance;
  }
  // @ts-ignore
  constructor(page) {
    this.page = page;
  }

  /**
   * 全局绑定listener来监听页面click事件。
   */
  _trackerPageReport() {
    const eventMethodObj = getEventListenerMethod();
    const eventName = "click";
    localStorage.setItem(CURRENT_PAGE_BY_LISTENED, this.page);
    // @ts-ignore
    window[eventMethodObj.addMethod](eventMethodObj.prefix + eventName, enhanceListenCb, {
      capture: false
    });
  }

  _removeTrackerPageReport() {
    const eventMethodObj = getEventListenerMethod();
    const eventName = "click";
    // @ts-ignore
    window[eventMethodObj.removeMethod](eventMethodObj.prefix + eventName, enhanceListenCb);
    localStorage.removeItem(CURRENT_PAGE_BY_LISTENED);
  }
}
export { TrackerReport };
