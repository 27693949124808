import React, { CSSProperties, memo } from "react";

interface IProps {
  mailto: string; // 收件人
  btnContent: string;
  subject?: string; // 主题
  cc?: string; // 抄送
  bcc?: string; // 暗送
  body?: string; // 内容
  className?: string;
}

export const CommonMailto = memo(
  (props: IProps) => {
    const { mailto, btnContent, subject = "", cc = "", bcc, body = "", className } = props;
    const _href = "mailto:" + mailto + "?cc=" + cc + "&subject=" + subject + "&body=" + body + "";
    return (
      <a href={_href} className={className}>
        {btnContent}
      </a>
    );
  },
  (prevProps: IProps, nextProps: IProps) => {
    return (
      prevProps.mailto === nextProps.mailto &&
      prevProps.btnContent === nextProps.btnContent &&
      prevProps.subject === nextProps.subject &&
      prevProps.body === nextProps.body
    );
  }
);
