import { SAAS } from "@aspen/libs";

// 如不同的客户需要不同的menu 直接在此修改
export const SAAS_HEADER_MENU_PATHS = [
  {
    id: "saas.menu.investor",
    to: SAAS.INVESTOR
  },
  {
    id: "saas.menu.venture",
    to: SAAS.VENTURES
  },
  {
    id: "saas.menu.strategy",
    to: SAAS.STRATEGIES
  },
  {
    id: "saas.menu.market",
    to: SAAS.MARKET
  },
  {
    id: "saas.menu.investment",
    to: SAAS.INVESTMENT
  },
  {
    id: "saas.menu.wallet",
    to: SAAS.WALLET
  },
  {
    id: "saas.menu.contact",
    to: SAAS.CONTACT
  }
];

// 不同需求在此修改权限配置
export const BASE_PERMISSION_CONFIG = {
  showPoweredByAspen: false, // 资讯是否显示由Aspen提供
  showStrategyModule: true, // 系统内是否显示Strategy模块
  showVentureModule: true // 系统内是否显示Venture模块 ,无接口数据支持 暂时无用
};
