import React, { useEffect, useMemo, useState } from "react";
import Header from "../header/Header";
import { IInjectProps } from "@aspen/model";
import { saasGetTenantInfo } from "@aspen/services";
import { SaasContext } from "@aspen/store";
import {
  i18nUtil,
  SAAS_CURRENT_DOMAIN,
  SAAS_TENANT_ID,
  SAAS_TENANT_INFO,
  getTenantDomainName,
  getTenantStorageKey,
  LOGIN_PATHS,
  isClient,
  IRole
} from "@aspen/libs";
import { message } from "@aspen/ui";

const defaultProps = {};

type IProps = {
  children: React.ReactNode;
  routeConfig: IRole;
  headerMenu: { id: string; to: string }[];
} & IInjectProps &
  Partial<typeof defaultProps>;

export const WithSaasLayout: React.FC<IProps> = (props: IProps) => {
  const intl = i18nUtil.t();
  const [tenantUpdate, setTenantUpdate] = useState(false);

  const getTenantInfo = (domainName: string) => {
    saasGetTenantInfo(domainName).then((res) => {
      if (res?.code == "0" && res?.data?.id) {
        localStorage.setItem(getTenantStorageKey(SAAS_TENANT_ID), res?.data?.id);
        localStorage.setItem(getTenantStorageKey(SAAS_TENANT_INFO), JSON.stringify(res?.data));
        setTenantUpdate(true);
      } else {
        if (res?.msg === "no payload data") {
          message.error(intl["saas.tenant.not.exist"]);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      }
    });
  };
  useEffect(() => {
    if (location?.pathname !== LOGIN_PATHS.LOGIN) return;
    const tenantInfo = isClient && localStorage.getItem(getTenantStorageKey(SAAS_TENANT_INFO));
    const currentDomainName =
      isClient && localStorage.getItem(getTenantStorageKey(SAAS_CURRENT_DOMAIN));
    const domainName = getTenantDomainName();
    if (tenantInfo && currentDomainName === domainName) return;
    if (domainName === "") return;
    localStorage.setItem(SAAS_CURRENT_DOMAIN, domainName);
    getTenantInfo(domainName);
  }, []);
  const providerValues = useMemo(() => {
    return { tenantUpdate };
  }, [tenantUpdate]);
  return (
    <SaasContext.Provider value={providerValues}>
      <div style={{ height: "100vh", minWidth: 1440, display: "flex", flexDirection: "column" }}>
        <Header routeConfig={props.routeConfig} headerMenu={props.headerMenu} />
        <div className="saasContainer">{props.children}</div>
      </div>
    </SaasContext.Provider>
  );
};
