"use client";
import React, { useEffect, useState } from "react";
import styles from "./Header.module.less";
import Image from "next/image";
import { useRouter } from "next/router";
import type { MenuProps } from "antd";
import { Dropdown, Layout, Menu } from "antd";
import type { IRole } from "@aspen/libs";
import {
  getCurrentPathName,
  i18nUtil,
  clearLoginStorage,
  SAAS,
  SAAS_IS_LOGIN,
  isClient
} from "@aspen/libs";
import clsx from "clsx";
import { saasLogout } from "@aspen/services";
import { useSaasTenant } from "@aspen/hooks";

const saas_avatar = require("@aspen/assets/images/saas/saas_avatar.webp");

interface IProps {
  routeConfig: IRole;
  headerMenu: { id: string; to: string }[];
}

const Header: React.FC<IProps> = (props: IProps) => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const { headerMenu } = props;

  const tenant = useSaasTenant();

  const [current, setCurrent] = useState<string[]>([]);

  useEffect(() => {
    const pathList = headerMenu.map((item) => item.to);
    const path = router?.pathname?.toString();
    let defaultCurrent: string[] = [];
    pathList.map((p) => {
      if (path.indexOf(p) !== -1) {
        defaultCurrent.push(p);
      }
    });
    setCurrent(defaultCurrent);
  }, [router?.pathname]);

  const getPopupContainer = (trigger: HTMLElement) =>
    (trigger.parentNode || document.body) as HTMLElement;
  const isSaasLogin = isClient ? window?.localStorage.getItem(SAAS_IS_LOGIN) === "true" : false;

  const handleLogout = () => {
    saasLogout().then((res) => {
      if (res?.code == "0") {
        clearLoginStorage();
        router.push(SAAS.LOGIN);
      }
    });
  };

  const handleClickProfile: MenuProps["onClick"] = ({ key }) => {
    if (key === "logout") {
      handleLogout();
    }
  };

  const renderMenu = () => {
    const menusItems = headerMenu?.map((item) => ({
      label: intl[item.id],
      key: item.to,
      popupOffset: [-5, -10]
    }));
    const dropdownMenu = (
      <Menu
        items={[
          {
            icon: <span className={clsx(styles.img, "icon iconfont aspen-logout")} />,
            label: (
              <span style={{ marginLeft: 16 }}>{i18nUtil.formatMessage({ id: "logout" })}</span>
            ),
            key: "logout"
          }
        ]}
        onClick={handleClickProfile}
      />
    );

    const allMenusItems = [
      ...menusItems,
      {
        label: (
          <div className={styles.defaultDrop}>
            <Dropdown
              overlay={dropdownMenu}
              overlayClassName="logout-dropdown"
              trigger={["click"]}
              getPopupContainer={getPopupContainer}>
              <div className={styles.avatarBox}>
                <Image unoptimized src={saas_avatar} alt="" width="20" height="20" />
              </div>
            </Dropdown>
          </div>
        ),
        key: "setting"
      }
    ];

    return allMenusItems;
  };

  const handleClick = (e) => {
    if (e.key === "setting") {
      return;
    }
    const pathname = getCurrentPathName(e.keyPath.toString());
    setCurrent(pathname?.split(","));
    router.push(e.key);
  };
  return (
    <Layout.Header className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.logo}>
          {tenant?.logoUrl ? (
            <Image
              unoptimized
              alt=""
              src={tenant?.logoUrl}
              width={200}
              height={30}
              style={{
                objectFit: "contain",
                objectPosition: "left"
              }}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.headerRight}>
        {isSaasLogin ? (
          <Menu
            onClick={handleClick}
            selectedKeys={current}
            mode="horizontal"
            items={renderMenu()}
          />
        ) : null}
      </div>
    </Layout.Header>
  );
};

export default React.memo(Header);
