import React from "react";
import { i18nUtil, IRole, SAAS_HEADER_TITLE } from "@aspen/libs";

export const getPageHeader = ({
  routeConfig,
  pathname
}: {
  routeConfig: IRole;
  pathname: string;
}) => {
  const intl = i18nUtil.t();
  const path = pathname?.split("/[");
  const key = path?.[0];
  const routerTitleKey = routeConfig[key]?.titleKey ?? "";
  return [
    <link key="3" rel="icon" href="/favicon.ico" />,
    <title key="title">
      {SAAS_HEADER_TITLE} - {intl[routerTitleKey]}
    </title>
  ];
};
