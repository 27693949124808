// api统一配置

export const aspenApi = {
  customer: {
    verifyInvitationCode: "v3/auth/common/verify/invitation-code",
    register: "v3/auth/customer/register",
    sendEmailCode: "v3/auth/common/email/verification-code/{email}",
    sendAuthEmailCode: "v3/auth/common/email/verification-code", //登陆后的发送邮箱验证码
    verifyEmailCode: "customer/email/verifyCode", // 暂时弃用 接口已删除 若要启用需后端配合
    login: "v3/auth/customer/login",
    subLogin: "v3/auth/sub-customer/login",
    logout: "v3/auth/customer/logout",
    subLogout: "v3/auth/sub-customer/logout",
    agentLogout: "v3/auth/agent/logout",
    managerLogout: "v3/auth/manager/logout",
    resetPassword: "v3/auth/customer/reset-pwd",
    forgetResetPassword: "v3/auth/customer/forget-pwd",
    subResetPassword: "v3/auth/sub-customer/reset-pwd",
    subForgetResetPassword: "v3/auth/sub-customer/forget-pwd",
    generateKey: "v3/auth/google/keys",
    googlebind: "v3/auth/google/bind",
    removeBind: "v3/auth/google/unbind",
    ifExist: "auth/ifExist", // 暂时弃用 验证是否邮箱已被注册
    myInvitation: "v3/customers/invitees", // 我的邀请列表
    inviterInfo: "v3/customers/inviter", // 代理商信息
    changeEmail: "customer/update/email", // 暂时弃用 修改邮箱
    ossData: "v3/auth/common/oss-token", // 获取host信息
    signUploadUrl: "v3/oss/url/signature", // 获取上传图片签名后地址（预览用）
    config: "v3/auth/common/system-config", // 获取配置
    customerConfig: "v3/customers/config", // 获取登陆后配置（交易fee和是否需要改密）
    modifyAuthorizationType: "v3/agent/authorization-type", //修改秘书账号授权类型
    agentAuthorization: "v3/agent/authorization", //查询代理商代客操作授权信息 、允许put、终止 delete
    subInfo: "v3/sub-customers",
    inviteSub: "v3/sub-customers/invite",
    registerSub: "v3/auth/sub-customer/register",
    stopSub: "v3/sub-customers/authorization",
    modifySubAuthorizationType: "v3/sub-customers/authorization-type",
    customerInfo: "v3/customers", //客户信息
    manageInfo: "v3/sas/customers/{customerId}", //管理员查询客户信息
    allowHelpOperate: "v3/customers/manager/config", // 允许帮客操作开关
    device: "v3/customer/devices", // 获取设备信息上报后端
    customerSetting: "v3/auth/common/account-config", // 获取登录验证渠道
    switchLanguage: "v3/info/zone",
    phoneOperate: "v3/auth/phone/bind", // post-绑定手机 put-换绑手机 delete-解绑手机
    getPhoneCode: "/v3/auth/common/phone/verification-code", // 获取手机验证码
    getPhoneCodeNoLogin: "/v3/auth/common/phone/verification-code/{phone}", // 获取手机验证码 无需登录
    securityVerify: "v3/auth/common/security-verify", // 安全验证
    userEventCheck: "v3/auth/common/user-event-pre-check", // 获取用户是否能做某个事件(目前业务只针对ApplyWithdraw做校验。校验是否能够出金/提币)
    reportList: "v3/customers/reportList", // 获取已生成的月结账单列表
    report: "/v3/customers/report" // 下载已生成的月结账单
  },
  member: "v3/member/equities/{equityType}",
  memberAum: "v3/statistics/asset/aum",
  kyc: {
    submit: "v3/kyc", // 提交kyc信息
    get: "v3/kyc", // 获取kyc信息
    risk: "v3/kyc/risk-level" // 获取kyc风险等级
  },
  onfido: {
    getToken: "v3/kyc/onfido/token", //获取 onfidoToken
    check: "v3/kyc/onfido/check"
  },
  // 现货币种可以用余额
  balance: "v3/accounts/balances",
  balances: "v3/accounts/balances",
  accountHistory: "v3/accounts/{currency}/logs",
  savings: {
    flexible: {
      products: "v3/finance/flexible/products",
      subscribe: "v3/finance/flexible/subscription", // 申购/追加
      available: "v3/finance/flexible/redemption-quota", // 可赎回对应币种的数量
      redeem: "v3/finance/flexible/redemption", //赎回
      accounts: "savings/flexible/accounts", // 理财账户明细
      yieldBanlance: "v3/finance/flexible/balance",
      history: "v3/finance/flexible/logs", // 申购/赎回历史记录
      list: "v3/finance/flexible/product-list", //活期产品列表
      autoSubscribe: "v3/finance/flexible/config", // 开启/关闭 自动申购
      changeInterestType: "v3/finance/flexible/interest-type", // 开启/关闭 利息滚投
      interestTrading: "v3/finance/flexible/config", // 开启/关闭 利息买币
      interestTradingInfo: "v3/trade/orders/flexible" // 获取利息买币的最新一条信息
    },
    fixed: {
      history: "v3/finance/fixed/logs", // 定期申购历史
      productList: "v3/finance/fixed/products", // 定期产品列表
      productDetail: "v3/finance/fixed/products/{id}", // 定期产品详情
      subscribe: "v3/finance/fixed/subscription" // 定期产品申购
    },
    staking: {
      productList: "v3/finance/staking/products", // 产品列表
      productDetail: "v3/finance/staking/products/{id}", // 产品详情
      quot: "v3/finance/staking/subscription-quota",
      subscribe: "v3/finance/staking/subscription", // 质押
      redeem: "v3/finance/staking/redemption", // 赎回
      switchAutoRestake: "v3/finance/staking/config",
      history: "v3/finance/staking/logs" // staking历史
    },
    autoInvest: {
      products: "v3/finance/investment/products", //定投产品列表
      productDetail: "v3/finance/investment/products/{id}", //定投产品详情
      contracts: "v3/finance/investment/contracts", //我的定投合约列表V2,修改了接口响应结构
      contractDetail: "v3/finance/investment/contracts/{contractId}", //我的定投合约详情
      history: "v3/finance/investment/logs", //我的定投操作日志
      strategy: "v3/finance/investment/strategy", //是否存在策略定投合约
      minAmount: "v3/finance/investment/{productId}/strategy-plans", //策略的起购额列表
      currencyScope: "v3/finance/investment/currencies", //定投支持的币种列表
      subscribe: "v3/finance/investment/subscription", //申购/追加定投产品
      stop: "v3/finance/investment/termination", //终止定投产品
      autoFinancing: "v3/finance/investment/config", //开启/关闭自动转活期,
      ytdTrend: "v3/market/ytd-trend" //获取btc收益曲线
    },
    newFixed: {
      productList: "v3/finance/fixed-yield/products", //定期产品列表
      productDetail: "v3/finance/fixed-yield/products/{id}", //定期产品详情
      subscribe: "v3/finance/fixed-yield/subscription", // 定期产品申购
      config: "v3/finance/fixed-yield/config", //更新续期选择
      history: "v3/finance/fixed-yield/logs", //操作记录
      yieldFixedContracts: "v3/finance/fixed-yield/contracts", //获取定期持仓合约列表
      yieldFixedContractDetail: "v3/finance/fixed-yield/contract/detail" //获取定期持仓合约详情
    }
  },
  strategies: {
    //策略线上化
    strategyList: "v3/strategy/products", // 策略列表
    strategyDetail: "v3/strategy/position/{strategyId}", // 策略详情
    strategyApply: "v3/strategy/subscription", // 策略申购意向
    strategyRedeem: "v3/strategy/redemption", // 策略赎回意向
    strategyOrderList: "v3/strategy/orders", // 查询策略订单历史列表
    strategyOrderCancel: "v3/strategy/orders/{orderId}", // delete 取消策略意向
    strategyOrderDetail: "v3/strategy/logs/{orderId}" // 查询策略订单流转信息
  },
  fund: {
    fundApply: "v3/fund/subscription", // 申购意向
    fundRedeem: "v3/fund/redemption", // 策略赎回意向
    fundList: "v3/fund/products", // fund列表
    fundDetail: "v3/fund/position/{fundId}", // 查询指定fund产品持仓情况
    fundOrderList: "v3/fund/orders", // 查询订单历史列表,
    fundIntentionAmount: "v3/fund/fund-intention-amount/{product-id}" // 查询指定产品已提交的赎回意向总金额
  },
  ventures: {
    ventureList: "v3/venture/products", // 风投列表
    ventureDetail: "v3/venture/position/{ventureInfoId}", // 策略详情
    ventureOrderList: "v3/venture/orders", // 查询风投订单历史列表
    ventureShares: "v3/venture/share-transfer", // 股权出售意向
    ventureApply: "v3/venture/subscription", // 风投申购意向
    ventureRedeem: "v3/venture/redemption", // 风投赎回意向
    ventureIntentionAmount: "v3/venture/venture-intention-amount/{product-id}", // 查询指定风投产品已提交的赎回意向总金额
    venturePosition: "v3/venture/position" // 查询所有风投产品持仓情况
  },
  structured: {
    history: "v3/structured/dual/orders", // 查询双币订单历史列表
    specificList: "v3/structured/dual/products/{underlying}/{quote}/{type}",
    productDetail: "v3/structured/dual/products/{id}",
    getOrderId: "v3/structured/dual/orders/id",
    order: "v3/structured/dual/orders", //get:获取订单列表 post:下单
    orderDetail: "v3/structured/dual/orders/{orderId}",
    underlying: "v3/structured/dual/products"
  },
  accumulator: {
    underlying: "v3/structured/accumulator/products", // 查询累计期权理财产品列表
    order: "v3/structured/accumulator/orders", // get:获取订单列表 post:累计期权下单
    productList: "v3/structured/accumulator/products/{underlying}/{quote}", // 查询累计期权理财产品列表
    productDetail: "v3/structured/accumulator/products/{underlying}/{quote}/{id}", // 查询累计期权理财产品详情
    orderDetail: "v3/structured/accumulator/orders/{orderId}", // 获取订单详情
    customizedService: "v3/structured/accumulator/customized-service" // 定制服务
  },
  snowball: {
    underlying: "v3/structured/snowball/products", // 查询雪球产品列表
    order: "v3/structured/snowball/orders", // get:获取订单列表 post:雪球下单
    productList: "v3/structured/snowball/products/{underlying}/{quote}", // 查询雪球盘口列表
    productDetail: "v3/structured/snowball/products/{underlying}/{quote}/{id}", // 查询雪球产品详情
    orderDetail: "v3/structured/snowball/orders/{orderId}", // 获取订单详情
    customizedService: "v3/structured/snowball/customized-service" // 雪球定制服务
  },
  intelligence: {
    article: "v3/info/articles", // 新闻列表 新闻类型（STORY 爬虫新闻、NOTICE 公告消息、BRIEF 简报、REPORT 研报、WEEKLY_REPORT 周报、EXPERT_POST 专栏）
    articleDetail: "v3/info/articles/{id}", // 新闻详情
    articlesReaded: "v3/info/articles/status", // 通知已读上报 type (NOTICE 公告消息、 BRIEF 日报、 WEEKLY_REPORT 周报、 REPORT 研报)
    expert: {
      authorList: "v3/info/experts", // 查看专栏作家列表
      authorDetail: "v3/info/experts/{id}", // 查看专栏作家详情信息
      follow: "v3/info/experts/follows", // 关注
      feedback: "v3/info/articles/feedback", // 反馈通知
      followed: "v3/info/experts/follows/{followId}" // 查询关注关系
    }
  },
  events: {
    list: "v3/conference", // events列表
    detail: "/v3/conference/{id}", // events详情
    book: "v3/conference/book", // 活动报名
    cancelBook: "/v3/conference/cancelBook" // 取消活动报名
  },
  wallet: {
    walletInfo: "v3/wallet/info", //获取钱包信息
    spot: "v3/wallet/accounts/spot", //获取现货账户信息
    yield: "v3/wallet/accounts/yield", //获取理财账户信息
    yieldFixedContracts: "v3/finance/fixed/contracts", // 获取定期持仓合约列表
    yieldFixedContractsDetail: "v3/finance/fixed/contracts/{id}", // 获取定期持仓合约详情
    yieldFixedCancelSubscription: "v3/finance/fixed/subscription", // 撤销申购
    yieldFixedTransferToFlexible: "v3/finance/fixed/config", // 定期到期自动转活期开关
    yieldFixedSwitchAutoRenewal: "v3/finance/fixed/config", // 定期续期开关
    yieldStakingContracts: "v3/finance/staking/contracts", // 获取staking持仓合约列表
    yieldStakingContractsDetail: "v3/finance/staking/contracts/{id}", // 获取staking持仓合约详情
    strategies: "v3/wallet/accounts/strategy", //获取策略账户信息
    ventures: "v3/wallet/accounts/venture",
    fund: "v3/wallet/accounts/fund",
    updates: "/v3/updates",
    updatesDetail: "/v3/updates/detail",
    updatesRead: "/v3/updates/read/{id}",
    structured: "v3/structured/dual/positions", // 获取双币持仓列表
    accumulators: "v3/structured/accumulator/positions", // 获取累计期权持仓汇总
    snowball: "v3/structured/snowball/positions", // 获取雪球持仓汇总
    accumulatorAccountsDetail: "v3/structured/accumulator/orders", // 获取累计期权持仓详情
    snowballAccountsDetail: "v3/structured/snowball/orders", // 获取累计期权持仓详情
    structuredAccountsDetail: "v3/structured/dual/orders", // 获取双币持仓详情
    depositAddress: "v3/wallet/deposit-addresses/{currency}", //获取充币地址 没有则新增
    applyWithdraw: "v3/wallet/withdrawal", //电子货币提币
    preWithdraw: "v3/wallet/withdrawal/pre-check", //判断是否可以出金
    depositWithdrawHistory: "v3/wallet/deposit-withdrawal/history", //充提历史
    // 提币白名单
    withdrawAddressList: "v3/wallet/withdrawal/addresses", // 提币地址列表
    withdrawEnableWhiteInfo: "v3/wallet/withdrawal/config", // 查询用户是否开启提币白名单等信息
    withdrawWhiteListHistory: "v3/wallet/withdrawal-whitelist/history", // 提币白名单申请历史记录
    withdrawWhiteListOnAndOff: "v3/wallet/withdrawal-whitelist/switch", // 提币白名单开关
    withdrawAddAddress: "v3/wallet/withdrawal-addresses", // 新增提币地址
    withdrawDelete: "v3/wallet/withdrawal-addresses/{addressId}", // delete 删除提币地址
    withdrawAddressSource: "v3/wallet/addresses/source", // 地址来源
    withdrawCheckAddress: "v3/wallet/addresses/check", // 校验地址是否合规
    withdrawWhiteList: "v3/wallet/withdraw/update-whitelist-address", // 加入提币白名单
    withdrawNormalList: "v3/wallet/withdraw/update-normal-address", // 移除提币白名单
    //circle 暂时无用
    circleDepositInfo: "v3/wallet/circle/deposit-guides", //入金信息
    circleWithdraw: "v3/wallet/withdrawal/circle", //出金
    circleHistory: "v3/wallet/circle/orders", //获取circle交易历史
    circleLimit: "v3/wallet/withdrawal/circle/config", //获取出金周限额、月限额
    // circle 未更新v3 暂时无用
    bankAccount: "bank/circle/bankaccount", //获取银行账户信息
    bankList: "bank/circle/banks", //circle支持的银行列表
    addCircleAccount: "bank/upsert/bankaccount", // 整合 添加银行账户、重新添加银行账户为一个接口

    amfIsEnough: "v3/wallet/asset-management-fee", //判断是否欠资产管理费
    amfDeduct: "v3/wallet/amf/deduction", //手动扣除资产管理费
    assetCurrencyConfig: "v3/wallet/asset-chains", //获获取资产链配置
    // 冷钱包 vault
    vault: {
      list: "v3/cold-wallet/accounts", // 获取冷钱包账户明细
      assetCurrencyConfig: "v3/wallet/asset-chains", // 获取币种的资产配置
      showDepositAddress: "v3/wallet/deposit-addresses/{currency}", // 获取已存在的充币地址
      deposit: "v3/cold-wallet/deposit", // 充币
      withdraw: "v3/cold-wallet/withdrawal", // 提币
      depositWithdrawHistory: "v3/cold-wallet/deposit-withdrawal/history" // 冷钱包的充提历史
    },
    otcOrders: "v3/wallet/otc/orders", //otc订单历史
    otcIntentionOrder: "v3/wallet/otc/intention/order" //otc意向订单
  },
  trading: {
    acquireOrderId: "v3/trade/orders/id", // 获取订单id
    orders: "v3/trade/orders", //下单
    cancelOrder: "v3/trade/orders/{orderId}", // 取消订单
    orderDetail: "v3/trade/trades/{orderId}", // trade order history
    favoritesList: "v3/market/favorites", // get 获取自选列表
    addFavorites: "v3/market/favorites", // post 新增自选
    removeFavorites: "v3/market/favorites", // delete 移除自选
    sortFavoritesList: "v3/market/favorites/sort", // 排序自选列表 ，包括统一新增删除
    recommemdList: "v3/market/favorites/recommend" // 推荐自选列表
  },
  market: {
    // https://stackedit.io/app#providerId=googleDriveWorkspace&folderId=1TZZduUcByn8Wrn5gCDFBj_XTzMW8CWIF
    symbol: {
      list: "v3/market/symbols", // 盘口数据列表
      offline: "v3/market/symbols/offline" // 离线盘口数据列表
    },

    // https://stackedit.io/app#providerId=googleDriveWorkspace&folderId=1TZZduUcByn8Wrn5gCDFBj_XTzMW8CWIF
    currency: {
      coinList: "v3/market/currencies" // 所有币种、名称、icon等
    },

    // https://stackedit.io/app#providerId=googleDriveWorkspace&folderId=1TZZduUcByn8Wrn5gCDFBj_XTzMW8CWIF
    orderbooks: "v3/market/orderbooks/{symbol}"
  },
  assets: {
    static: "v3/statistics/asset",
    currencies: "v3/statistics/asset/positions/currencies",
    revenues: "v3/statistics/asset/overview",
    accountAssets: "v3/accounts/assets"
  },
  client: {
    customerList: "v3/cms/agents/customers",
    info: "v3/cms/agents",
    commissionSummary: "v3/cms/agents/commission-summary",
    commissionList: "v3/cms/agents/commission-detail",
    AMFSummary: "v3/customers/amf-summary",
    AMFList: "v3/customers/amf-detail",
    agentLogin: "v3/auth/agent/login",
    operateCustomer: "v3/auth/agent/operating",
    applyRole: "v3/agent/authorization",
    // cms
    client: "v3/cms/agents/client",
    revenue: "v3/cms/agents/revenue",
    aum: "v3/cms/agents/customer-aum-monthly",
    recent: "v3/cms/agents/customer-recent-activities",
    assetSummary: "v3/cms/agents/customer-asset-summary",
    assetSummaryDetail: "v3/cms/agents/customer-asset-summary/detail",
    sessionStatistics: "v3/cms/agents/customer-session-statistics",
    monitorTypes: "v3/cms/agents/business-monitor-types",
    walletAssets: "v3/cms/agents/wallet/info",
    assetAllocation: "v3/cms/agents/asset/positions/currencies",
    spotAccount: "v3/cms/agents/accounts/spot",
    flexibleAccount: "v3/cms/agents/accounts/yield",
    fixedAccount: "v3/cms/agents/finance/fixed-yield/contracts",
    stakingAccount: "v3/cms/agents/finance/staking/contracts",
    dualAccount: "v3/cms/agents/structured/dual/positions",
    accumulatorTableAccount: "v3/cms/agents/structured/accumulator/positions",
    snowballTableAccount: "v3/cms/agents/structured/snowball/positions",
    strategyAccount: "v3/cms/agents/accounts/strategy",
    ventureAccount: "v3/cms/agents/accounts/venture",
    fundAccount: "v3/cms/agents/accounts/fund",
    sessionsHistory: "v3/cms/agents/customer-session-details",
    recentSessions: "v3/cms/agents/customer-session-detail-statistics-monthly",
    ventureList: "v3/cms/agents/product/list-products",
    ventureRecentVisit: "v3/cms/agents/product/list-recent-visit",
    ventureInterested: "v3/cms/agents/product/ventures/interested",
    ventureSubscriptions: "v3/cms/agents/product/ventures/subscriptions",
    stakingSubscriptions: "v3/cms/agents/product/list-product-subscribes",
    ventureAumProportion: "v3/cms/agents/product/product_aum_proportion",
    ventureCustomerAumProportion: "v3/cms/agents/product/product_aum_proportion_visit_event",
    listProductBusiness: "v3/cms/agents/product/list-product-business",
    fundSubscriptions: "v3/cms/agents/product/funds/subscriptions",
    // 查询代理商下属客户Staking产品按月统计的Aum
    stakingAum: "v3/cms/agents/staking/aum",
    // 查询代理商下属客户操作Staking产品的事件数（按月统计）
    stakingEvent: "v3/cms/agents/staking/event",
    // 查询代理商下属客户Staking产品按月统计的持仓人数
    stakingSubscriber: "v3/cms/agents/staking/subscriber",
    // 分页查询用户交易订单记录
    tradeOrders: "v3/cms/agents/trade/orders",
    // 客户交易分析对比历史增量增幅等信息
    tradeAnalysis: "v3/cms/agents/trade/analysis",
    // 查询代理商下属客户按月统计的订单交易量
    tradeVolume: "v3/cms/agents/trade/volume",
    // 查询代理商下属客户按月统计的订单交易人数
    tradeTrader: "v3/cms/agents/trade/trader",
    // 查询代理商下属客户按月统计的订单交易事件数
    tradeEvent: "v3/cms/agents/trade/event",
    // 查询代理商下属客户按月统计的月末现货aum
    tradeAum: "v3/cms/agents/trade/aum",
    // 分页获取结构化产品订单
    structuredOrders: "v3/cms/agents/structured/orders",
    // 获取结构化产品交易量占比增幅等信息
    structuredAnalysis: "v3/cms/agents/structured/analysis",
    // 获取结构化产品列表
    structuredProducts: "v3/cms/agents/structured/products",
    // 查询代理商下属客户按月统计的结构化理财产品持有人数
    structuredSubscriber: "v3/cms/agents/structured/subscriber",
    // 查询代理商下属客户按月统计的结构化理财产品事件数
    structuredEvent: "v3/cms/agents/structured/event",
    // 查询代理商下属客户按月统计的月末现货aum
    structuredAum: "v3/cms/agents/structured/aum",
    // 帮客
    managerLogin: "v3/auth/manager/login",
    managerOperate: "v3/auth/manager/operating",
    changePassword: "v3/auth/manager/reset-pwd"
  },
  flowable: {
    applicationList: "v3/workflow/processes"
  }
};
