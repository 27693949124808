import React from "react";
import styles from "./Modal.module.less";
import { i18nUtil } from "@aspen/libs";
import { CommonButton } from "@saas/ui";
import { Divider, Modal } from "antd";
import closeIcon from "@aspen/assets/images/saas/closeIcon.webp";
import successIcon from "@aspen/assets/images/saas/successIcon.webp";
import Image from "next/image";
interface IProps {
  visible: boolean;
  cancelModal: () => void;
  title: string;
  data: { title: string; subTitle: string }[];
}

const ModalSuccess: React.FC<IProps> = (props: IProps) => {
  const intl = i18nUtil.t();
  const { visible, cancelModal, title, data } = props;
  return (
    <Modal
      wrapClassName={styles.modal}
      open={visible}
      centered
      width={500}
      destroyOnClose={true}
      footer={null}
      closeIcon={<Image unoptimized width={48} height={48} src={closeIcon} alt="" />}
      onCancel={cancelModal}>
      <div className={styles.modalResult}>
        <div className={styles.iconSuccess}>
          <Image unoptimized width={64} height={64} src={successIcon} alt="" />
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.dataBox}>
          {data.map((item) => (
            <div className={styles.itemBox} key={item.title}>
              <div className={styles.itemTitle}>{item.title}</div>
              <div className={styles.itemSubTitle}>{item.subTitle}</div>
            </div>
          ))}
        </div>
        <Divider className={styles.divider} />
        <div className={styles.okBtnBox}>
          <CommonButton className={styles.okBtn} key="ok" btntype="solid" onClick={cancelModal}>
            {intl["saas.button.close"]}
          </CommonButton>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ModalSuccess);
