import React, { useMemo, useState } from "react";
import styles from "./Modal.module.less";
import { i18nUtil, numberToThousands, reportEvent, validAmount } from "@aspen/libs";
import { CommonButton } from "@saas/ui";
import { Input, Modal } from "antd";
import closeIcon from "@aspen/assets/images/saas/closeIcon.webp";
import Image from "next/image";
import {
  BENCHMARK_CURRENCY,
  BENCHMARK_CURRENCY_SYMBOL,
  GA_EVENT_TAG,
  SAAS_GA_EVENT_NAME
} from "@aspen/libs";
import {
  saasRedeemStrategy,
  saasRedeemVenture,
  saasSubscribeStrategy,
  saasSubscribeVenture
} from "@aspen/services";
import { message } from "@aspen/ui";
import { IReqSaasSubscribeVenture } from "@aspen/model";

export type ModalType = "subscribe" | "redeem" | "sell";
export type ProductType = "strategy" | "venture";

interface IProps {
  visible: boolean;
  cancelModal: () => void;
  successAction: (data: { amount: number; currency: string }) => void;
  id: string;
  type: ProductType;
  modalType: ModalType;
  title: string;
  productName: string;
  positionAmount: number;
  data: { title: string; subTitle: string }[];
}

const ModalAction: React.FC<IProps> = (props: IProps) => {
  const intl = i18nUtil.t();
  const {
    visible,
    cancelModal,
    successAction,
    title,
    productName,
    data,
    id,
    modalType,
    type,
    positionAmount
  } = props;
  const method = useMemo(() => {
    if (modalType === "subscribe") {
      return type === "venture" ? saasSubscribeVenture : saasSubscribeStrategy;
    }
    return type === "venture" ? saasRedeemVenture : saasRedeemStrategy;
  }, [modalType]);

  const [amount, setAmount] = useState<number | string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeAmount = (e) => {
    const inputValue = e.target.value;
    const result = validAmount(inputValue, 2);
    if (result === false) return;
    setAmount(result);
  };
  const handleSubmit = () => {
    if (["redeem", "sell"].includes(modalType)) {
      if (Number(amount) > positionAmount) {
        message.error(intl["saas.position.amount.error"]);
        return;
      }
    }
    setIsLoading(true);
    const params: IReqSaasSubscribeVenture = {
      id,
      amount: Number(amount) ?? 0
    };
    reportEvent({
      moduleName: SAAS_GA_EVENT_NAME.product[modalType],
      joinedTag: GA_EVENT_TAG.Click,
      detailParams: {
        ...params,
        id: `id=${id}`,
        type
      }
    });
    method(params)
      .then((res) => {
        if (res?.code == "0") {
          successAction &&
            successAction({ amount: Number(amount) ?? 0, currency: BENCHMARK_CURRENCY });
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      wrapClassName={styles.modal}
      open={visible}
      centered
      width={500}
      title={title}
      destroyOnClose={true}
      footer={null}
      closeIcon={<Image unoptimized width={48} height={48} src={closeIcon} alt="" />}
      onCancel={cancelModal}>
      <div className={styles.modalResult}>
        <div className={styles.name}>{productName}</div>
        <div className={styles.subDataBox}>
          {data.map((item, index) => (
            <div className={styles.itemBox} key={`${item.title}${index}`}>
              <div className={styles.itemTitle}>{item.title}</div>
              <div className={styles.itemSubTitle}>{item.subTitle}</div>
            </div>
          ))}
        </div>
        <div className={styles.amountTitle}>{intl["saas.column.amount"]}</div>
        <div className={styles.amountInputBox}>
          <Input
            value={amount ?? ""}
            placeholder={intl["saas.login.placeholder"]}
            suffix={BENCHMARK_CURRENCY}
            onChange={onChangeAmount}
          />
          {["redeem", "sell"].includes(modalType) && (
            <div className={styles.positionAmount}>
              {intl["saas.column.fairValue"]}: {BENCHMARK_CURRENCY_SYMBOL}
              {numberToThousands(positionAmount)}
            </div>
          )}
        </div>
        <div className={styles.okBtnBox}>
          <CommonButton
            disabled={!amount || Number(amount) === 0 || isLoading}
            className={styles.okBtn}
            key="ok"
            btntype="solid"
            onClick={handleSubmit}>
            {intl["saas.button.submit"]}
          </CommonButton>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ModalAction);
