import React, { useMemo, useState } from "react";
import ModalAction, { ModalType } from "../modal/ModalAction";
import ModalSuccess from "../modal/ModalSuccess";
import {
  i18nUtil,
  decimalPointNoMoreX,
  numberToThousands,
  reportEvent,
  SAAS_GA_EVENT_NAME,
  GA_EVENT_TAG
} from "@aspen/libs";

interface IProps {
  [key: string]: any; // 保留此any, HOC组件，props可能为任意类型
}

export const WithProductActions = (WrappedComponent) => {
  const ProductActions: React.FC<IProps> = (props: IProps) => {
    const intl = i18nUtil.t();
    const { type, data } = props;
    const purchaseInformation = useMemo(() => {
      try {
        const info = JSON.parse(data?.purchaseInformation);
        const pInfo: { title: string; subTitle: string }[] = [];
        (Object.keys(info) ?? []).map((key) => {
          if (key.startsWith("title")) {
            const index = key.split("title");
            pInfo.push({ title: info[key], subTitle: info[`subtitle${index[1]}`] });
          }
        });
        return pInfo;
      } catch (e) {
        return [];
      }
    }, [data?.purchaseInformation]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [modalType, setModalType] = useState<ModalType>("subscribe");
    const [positionAmount, setPositionAmount] = useState(0);
    const [visibleSuccess, setVisibleSuccess] = useState(false);
    const [subscribeData, setSubscribeData] = useState({ amount: "", currency: "" });

    const openSubscribe = (openModalType: ModalType, positionAmount?: number) => {
      reportEvent({
        moduleName: SAAS_GA_EVENT_NAME.product[openModalType],
        joinedTag: GA_EVENT_TAG.Modal,
        detailParams: {
          type,
          actionType: modalType,
          id: `id=${type === "venture" ? data?.ventureId : data?.strategyId}`
        }
      });
      setVisibleModal(true);
      setPositionAmount(positionAmount ?? 0);
      setModalType(openModalType);
    };
    const openSuccess = () => {
      reportEvent({
        moduleName: SAAS_GA_EVENT_NAME.product[`${modalType}Success`],
        joinedTag: GA_EVENT_TAG.Modal,
        detailParams: {
          type,
          actionType: modalType,
          id: `id=${type === "venture" ? data?.ventureId : data?.strategyId}`
        }
      });
      setVisibleSuccess(true);
    };
    const closeSubscribe = () => {
      setVisibleModal(false);
    };
    const closeSuccess = () => {
      setVisibleSuccess(false);
    };

    const subscribeSuccessAction = (data) => {
      closeSubscribe();
      openSuccess();
      setSubscribeData(data);
    };

    const modalTitle = useMemo(() => {
      const isVenture = type === "venture" ? "ventures" : "strategy";
      if (modalType === "subscribe") {
        return {
          actionTitle: intl[`saas.${isVenture}.subscription`],
          successTitle: intl[`saas.${isVenture}.subscription.submit`]
        };
      }
      if (modalType === "redeem") {
        return {
          actionTitle: intl[`saas.${isVenture}.redeem`],
          successTitle: intl[`saas.${isVenture}.redeem.submit`]
        };
      }
      return {
        actionTitle: intl["saas.ventures.sell"],
        successTitle: intl["saas.ventures.sell.submit"]
      };
    }, [modalType]);

    return (
      <>
        <WrappedComponent openSubscribe={openSubscribe} {...props} />
        {visibleModal && (
          <ModalAction
            id={data?.id}
            type={type}
            modalType={modalType}
            visible={visibleModal}
            cancelModal={closeSubscribe}
            successAction={subscribeSuccessAction}
            title={modalTitle.actionTitle}
            productName={data?.name}
            data={purchaseInformation}
            positionAmount={positionAmount}
          />
        )}
        {visibleSuccess && (
          <ModalSuccess
            visible={visibleSuccess}
            cancelModal={closeSuccess}
            title={modalTitle.successTitle}
            data={purchaseInformation.concat([
              {
                title: intl["saas.column.amount"],
                subTitle: `${numberToThousands(
                  decimalPointNoMoreX(Number(subscribeData.amount), 2)
                )} ${subscribeData.currency}`
              }
            ])}
          />
        )}
      </>
    );
  };

  return ProductActions;
};
