import dynamic from "next/dynamic";

export * from "./withProductActions/WithProductActions";

export const ProductItem = dynamic(() => import("./productItem/ProductItem"), {
  ssr: false
});

export const ModalSuccess = dynamic(() => import("./modal/ModalSuccess"), {
  ssr: false
});

export const ModalAction = dynamic(() => import("./modal/ModalAction"), {
  ssr: false
});

export const ActionBox = dynamic(() => import("./actionBox/ActionBox"), {
  ssr: false
});

export const ActionColumn = dynamic(() => import("./actionColumn/ActionColumn"), {
  ssr: false
});

export const VentureUpdateList = dynamic(() => import("./ventureUpdateList/VentureUpdateList"), {
  ssr: false
});
