import apikeyGa from "../constant/ga";
import apikeyGtm from "../constant/gtm";
import { DOWNLOAD_PATH } from "../constant";
import type { IEnvConfig } from "@aspen/model";
import { ADGM_BRAND } from "@aspen/model";
import { systemDetection } from "../../utils";
import { adgmConfig } from "./adgm";
import { aspenConfig } from "./aspen";
// 根据系统返回配置的API等信息
export const getConfigs = () => {
  const system = systemDetection();
  if (system === ADGM_BRAND) {
    return adgmConfig;
  }
  return aspenConfig;
};

let configs = getConfigs();
let config: IEnvConfig = configs.prod;
if (process.env.NEXT_PUBLIC_HOST_ENV == "dev") {
  config = configs.dev;
}
if (process.env.NEXT_PUBLIC_HOST_ENV == "beta") {
  config = configs.beta;
}
if (process.env.NEXT_PUBLIC_HOST_ENV == "pre") {
  config = configs.pre;
}
if (process.env.NEXT_PUBLIC_HOST_ENV == "prod") {
  config = configs.prod;
}

/**
 * 本地调试在此修改调用不同环境的接口
 * Note: 注意环境本地会 以ip+port访问，如果各环境账号密码一致，注意别误操作生产数据
 */

if (process.env.NODE_ENV != "production") {
  config = configs.dev;
}

let {
  klineChartUrl,
  tvChartUrl,
  env,
  host,
  apiKeyBugsnag,
  fallbackUrl,
  packageName,
  domainUriPrefix,
  webDomain,
  appOnlyDomain,
  operationServer,
  saasServer,
  brand
} = config;

let websocket = {
  host: config.websocket.host,
  stage:
    typeof window !== "undefined" && window.location.href.includes("aspendigital.co")
      ? "RELEASE"
      : "TEST",
  appCode: "c022929d0e1f4b0d8eace9cb9934bf0d",
  registerPath: "/api/register",
  unregisterPath: "/api/unregister"
};

//web端现在页面
const webDownload = `${webDomain + DOWNLOAD_PATH}?source=web`;
//apponly端下载页面
const appOnlyDownload = `${appOnlyDomain + DOWNLOAD_PATH}?source=web`;

export {
  websocket,
  host,
  klineChartUrl,
  tvChartUrl,
  apikeyGa,
  apikeyGtm,
  env,
  apiKeyBugsnag,
  fallbackUrl,
  packageName,
  domainUriPrefix,
  webDomain,
  operationServer,
  saasServer,
  webDownload,
  appOnlyDownload,
  brand
};
