import { Button } from "antd";
import React from "react";
import styles from "./CommonButton.module.less";
import clsx from "clsx";
import { NativeButtonProps } from "antd/lib/button/button";

/**
 * 默认large size，可覆盖
 * 紫色边框，透明背景，紫色字体 btntype传transparent
 * 无边框，紫色背景，白色字体 btntype传solid
 */
interface IProps extends NativeButtonProps {
  btntype?: "transparent" | "solid";
}

export const CommonButton = (props: IProps) => {
  const { disabled = false, btntype = "solid" } = props;
  return (
    <span className={clsx(styles[btntype], styles.btnBox)}>
      <Button size="large" disabled={disabled} {...props} />
    </span>
  );
};
