import React, { useEffect, useState } from "react";
import {
  SAAS_DEFAULT_INVESTOR_LIMIT,
  SAAS_INIT_PAGE_INFO,
  SAAS_INVESTOR_PARAMS_ACTION,
  SAAS_INVESTOR_PARAMS_TYPE,
  i18nUtil
} from "@aspen/libs";
import { getSaasInvestorPortal } from "@aspen/services";
import { message } from "@aspen/ui";
import type {
  IPagerInfo,
  INVESTOR_PORTAL_TYPE,
  AsInformationQueryRequest,
  AsInformationVO
} from "@aspen/model";

interface IProps {
  [key: string]: any; // 保留此any, HOC组件，props可能为任意类型
}

interface WrappedProps {
  informationType?: INVESTOR_PORTAL_TYPE;
}

export const WithInvestorList = (WrappedComponent, defaultProps: WrappedProps) => {
  const InvestorList: React.FC<IProps> = (props: IProps) => {
    const { informationType } = defaultProps;
    const intl = i18nUtil.t();
    const [data, setData] = useState<AsInformationVO[]>([]);
    const [pagerInfo, setPagerInfo] = useState<IPagerInfo>(SAAS_INIT_PAGE_INFO);
    const [isLoading, setIsLoading] = useState(false);

    const fetchInvestor = (_pagerInfo: IPagerInfo, _params?: AsInformationQueryRequest) => {
      setIsLoading(true);
      const type = informationType ?? _params?.informationType ?? "NEWS";
      const params: AsInformationQueryRequest = _params ?? {
        action: SAAS_INVESTOR_PARAMS_ACTION.ALL,
        type: SAAS_INVESTOR_PARAMS_TYPE[type],
        informationType: type,
        nextToken: _pagerInfo.nextToken,
        limit: SAAS_DEFAULT_INVESTOR_LIMIT
      };
      getSaasInvestorPortal(params)
        .then((res) => {
          if (res?.code == "0") {
            const isLastData =
              res?.data?.rows?.length === SAAS_DEFAULT_INVESTOR_LIMIT &&
              res?.data?.totalCount === _pagerInfo.page * SAAS_DEFAULT_INVESTOR_LIMIT;
            setPagerInfo({
              page: _pagerInfo.page,
              nextToken: isLastData ? "" : (res?.data?.nextToken ?? "")
            });
            setData(res?.data?.rows ?? []);
          } else {
            message.error(intl[res?.msg] ?? res?.msg);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    useEffect(() => {
      if (informationType) {
        fetchInvestor(SAAS_INIT_PAGE_INFO);
      }
    }, [informationType]);

    return (
      <WrappedComponent
        data={data}
        isLoading={isLoading}
        pagerInfo={pagerInfo}
        fetchInvestor={fetchInvestor}
        {...props}
      />
    );
  };
  return InvestorList;
};
