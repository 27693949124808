import type {
  IAccumulatorAccountRes,
  IAccumulatorCustomizedReq,
  IAccumulatorCustomizedRes,
  IAccumulatorOrderReq,
  IAccumulatorOrderRes,
  IAccumulatorProductDetailReq,
  IAccumulatorProductsReq,
  IAccumulatorProductsRes,
  IAccumulatorUnderlyingRes,
  IAutoInvestCurrenciesRes,
  IAutoInvestDetailRes,
  IAutoInvestMinAmountRes,
  IAutoInvestStrategyRes,
  IBtcYtdRes,
  ICancelOrder,
  ICreateOrder,
  IEventsListReq,
  IFixedIResponse,
  IFlexibleIResponse,
  IInvestmentIResponse,
  IOrders,
  IOtcHistoryListRes,
  IReqAccountHistory,
  IReqAddSub,
  IReqAddWithdrawAddress,
  IReqAgentApply,
  IReqAllowHelpOperate,
  IReqApplicationList,
  IReqAssetsCurrencies,
  IReqAssetsRevenues,
  IReqAssetsSituational,
  IReqAum,
  IReqAuthorizationType,
  IReqAutoCompound,
  IReqAutoInvestSubscribe,
  IReqAutoProducts,
  IReqAutoProductsDetail,
  IReqAutoProductsHistory,
  IReqAutoSubscribe,
  IReqBindPhone,
  IReqCancelAutomaticInvestmentPlan,
  IReqChangeEmail,
  IReqChangePhone,
  IReqClientAssetSummary,
  IReqClientAumProportion,
  IReqClientCustomerAssets,
  IReqClientCustomerAssetsAllocation,
  IReqClientCustomerAumProportion,
  IReqClientCustomerSessinosHistory,
  IReqClientCustomerWalletList,
  IReqClientInfo,
  IReqClientListProductBusiness,
  IReqClientProductList,
  IReqClientRecent,
  IReqClientRecentVisit,
  IReqClientSessionStatistics,
  IReqClientStakingAum,
  IReqClientStakingMonthlyParams,
  IReqClientSubscriptions,
  IReqClientVentureInterested,
  IReqCustomerList,
  IReqDeviceInfo,
  IReqExpertAuthorDetail,
  IReqExpertAuthorFollowed,
  IReqExpertAuthorList,
  IReqExpertFeedback,
  IReqExpertFollow,
  IReqFavoritesSymbols,
  IReqFavoritesSymbolsList,
  IReqFixedCancelSubscription,
  IReqFixedContractsDetails,
  IReqFixedContractsList,
  IReqFixedHistory,
  IReqFixedProductsDetails,
  IReqFixedProductsList,
  IReqFixedTransferToFlexible,
  IReqFlexibleHistory,
  IReqFlexibleProducts,
  IReqForgetResetPassword,
  IReqFundDetail,
  IReqGetPhoneCode,
  IReqGetPhoneCodeNoLogin,
  IReqGoogleBind,
  IReqGoogleRemove,
  IReqIfExist,
  IReqIntelligenceArticlesRead,
  IReqIntelligenceDetails,
  IReqIntelligenceNews,
  IReqInterestTrading,
  IReqKycIndividuals,
  IReqKycInstitutions,
  IReqLogin,
  IReqLoginChannel,
  IReqManagerLogin,
  IReqMemberAum,
  IReqMyAutoProductsDetail,
  IReqMyInvitation,
  IReqMyStrategyDetail,
  IReqNewFixedConfig,
  IReqNewFixedContractsList,
  IReqNewSubscribeFixedProducts,
  IReqRedeem,
  IReqRedeemAvailable,
  IReqRegister,
  IReqRegisterSub,
  IReqReport,
  IReqReportList,
  IReqResetPassword,
  IReqSecurityVerifyHeader,
  IReqSendEmailCode,
  IReqSpotBalance,
  IReqStakingContractsDetails,
  IReqStakingContractsList,
  IReqStakingHistory,
  IReqStakingProducts,
  IReqStopPartnerRole,
  IReqStopSub,
  IReqStrategyApply,
  IReqStrategyDetail,
  IReqStrategyList,
  IReqStrategyOrderCancel,
  IReqStrategyOrderDetail,
  IReqStrategyRedeem,
  IReqStructuredAccountList,
  IReqStructuredAnalysisParams,
  IReqStructuredDataParams,
  IReqStructuredList,
  IReqStructuredOrdersParams,
  IReqStructuredProductsParams,
  IReqStructuredSpeList,
  IReqSubscribe,
  IReqSubscribeFixedProducts,
  IReqTradeAnalysisParams,
  IReqTradeOrdersParams,
  IReqTradeVolumeParams,
  IReqUnbindPhone,
  IReqUserEventCheck,
  IReqVaultDepositWithdraw,
  IReqVentureApply,
  IReqVentureDetail,
  IReqVentureList,
  IReqVentureRedeem,
  IReqVentureShares,
  IReqVenturesUpdates,
  IReqVenturesUpdatesDetail,
  IReqVerifyEmailCode,
  IReqverifyInvitationCode,
  IReqWalletCheckWithdrawAddress,
  IReqWalletFiatHistory,
  IReqWalletHistory,
  IReqWalletWithdrawAddress,
  IReqWalletWithdrawAddressDelete,
  IReqWalletWithdrawWhiteListOnAndOff,
  IReqWalletWithdrawWhiteListUpdate,
  IReqWithdrawWhiteListHistory,
  IRes,
  IResClientApply,
  IResOrdersHistory,
  IResOrdersOpen,
  ISnowballAccountRes,
  ISnowballCustomizedReq,
  ISnowballCustomizedRes,
  ISnowballOrderReq,
  ISnowballOrderRes,
  ISnowballProductDetailReq,
  ISnowballProductsReq,
  ISnowballProductsRes,
  ISnowballUnderlyingRes,
  ISSRHeaders,
  IStakingListRes,
  IStakingQuotaRes,
  IStakingReqSubscribe,
  IStructuredDetailRes,
  IStructuredListRes,
  IStructuredOrderModel,
  IUploadQuery,
  IUploadSignUrl,
  IWalletAccounts,
  IWalletAddBankAccount,
  IWalletAssetsConfig,
  IWalletDepositAddress,
  IWalletInfo,
  IWalletVaultDepositAddress,
  IWalletWithdraw,
  IWalletWithdrawCircle,
  IYieldFixedHistoryRes,
  IYieldFlexListRes,
  IYieldNewFixedDetailRes,
  IYieldNewFixedListRes,
  RListVenturePositionOrder,
  TCancelEventsReq,
  TEventsDetailReq,
  TRegisterEventsReq
} from "@aspen/model";
import axiosInstance from "../http";
import { aspenApi as api } from "./api";
import type { BENCHMARK_CURRENCY, REFERENCE_CURRENCY } from "@aspen/libs";
import { addHeapIdentify } from "@aspen/libs";

/* -------------------- 资产相关 -------------------- */
export const getAssetsSituational = (params: IReqAssetsSituational): Promise<IRes> => {
  return axiosInstance.get(api.assets.static, { params }).then((res) => {
    return res?.data;
  });
};

export const getAssetsCurrencies = (params: IReqAssetsCurrencies): Promise<IRes> => {
  return axiosInstance.get(api.assets.currencies, { params }).then((res) => {
    return res?.data;
  });
};
export const getAssetsRevenues = (params: IReqAssetsRevenues): Promise<IRes> => {
  return axiosInstance.get(api.assets.revenues, { params }).then((res) => {
    return res?.data;
  });
};

/* -------------------- end 资产相关 -------------------- */

/* --------------------交易相关-------------------- */
/**
 * @description: 获取订单id
 * @params {}
 * @return {Promise}
 */
export const tradeAcquireOrderId = (): Promise<IRes> => {
  return axiosInstance.get(api.trading.acquireOrderId).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 下单,暂时只支持现货
 * @params {ICreateOrder}
 * @return {Promise}
 */
export const createTradeOrder = (params: ICreateOrder): Promise<IRes> => {
  return axiosInstance
    .post(api.trading.orders, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 取消订单
 * @params {ICancelOrder}
 * @return {Promise}
 */
export const cancelTradeOrder = (params: ICancelOrder): Promise<IRes> => {
  return axiosInstance
    .delete(api.trading.cancelOrder.replace(/{orderId}/, params.orderId))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询订单详情
 * @params {ICancelOrder}
 * @return {Promise}
 */
export const fetchOrderDetail = (params: ICancelOrder): Promise<IRes> => {
  return axiosInstance
    .get(api.trading.orderDetail.replace(/{orderId}/, params.orderId))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取open order history
 * @params {IOrders}
 * @return {Promise}
 */
export const ordersOpen = (params: IOrders): Promise<IResOrdersOpen> => {
  return axiosInstance.get(api.trading.orders, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取trade order history
 * @params {IOrders}
 * @return {Promise}
 */
export const ordersHistory = (params: IOrders): Promise<IResOrdersHistory> => {
  return axiosInstance.get(api.trading.orders, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取指定盘口的深度数据
 * @params { symbol: string }
 * @return {Promise}
 */
export const fecthDepthByCoin = (params: { symbol: string }): Promise<IRes> => {
  return axiosInstance.get(api.market.orderbooks.replace(/{symbol}/, params.symbol)).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取币种24小时最高/最低/交易量等
 * @params {}
 * @return {Promise}
 */
export const fecthSymbolList = (): Promise<IRes> => {
  return axiosInstance.get(api.market.symbol.list).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取离线盘口信息
 * @params {}
 * @return {Promise}
 */
export const fetchOfflineSymbolList = (): Promise<IRes> => {
  return axiosInstance.get(api.market.symbol.offline).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取用户名下有资产的货币
 * @params {}
 * @return {Promise}
 */
export const fetchAccountAssetsList = (): Promise<IRes> => {
  return axiosInstance.get(api.assets.accountAssets).then((res) => {
    return res?.data;
  });
};

//  自选交易对相关
/**
 * @description: 获取自选交易对列表
 * @params {}
 * @return {Promise}
 */
export const getFavoritesSymbolsList = (): Promise<IRes> => {
  return axiosInstance.get(api.trading.favoritesList).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取推荐自选交易对列表
 * @params {}
 * @return {Promise}
 */
export const getRecommemdSymbolsList = (): Promise<IRes> => {
  return axiosInstance.get(api.trading.recommemdList).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 添加自选交易对
 * @params {IReqFavoritesSymbols}
 * @return {Promise}
 */
export const addFavoritesSymbols = (params: IReqFavoritesSymbols): Promise<IRes> => {
  return axiosInstance
    .post(api.trading.addFavorites, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 移除自选交易对
 * @params {IReqFavoritesSymbols}
 * @return {Promise}
 */
export const removeFavoritesSymbols = (params: IReqFavoritesSymbols): Promise<IRes> => {
  return axiosInstance.delete(api.trading.removeFavorites, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 排序自选交易
 * @params {IReqFavoritesSymbolsList}
 * @return {Promise}
 */
export const sortFavoritesSymbolsList = (params: IReqFavoritesSymbolsList): Promise<IRes> => {
  return axiosInstance
    .post(api.trading.sortFavoritesList, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/* -------------------- end 交易相关-------------------- */

/*-------------------- 新闻相关 -------------------- */
/**
 * @description: 文章已读上报
 * @params {IReqIntelligenceArticlesRead}
 * @return {Promise}
 */
export const articlesRead = (params: IReqIntelligenceArticlesRead): Promise<IRes> => {
  return axiosInstance.post(api.intelligence.articlesReaded, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 新闻列表
 * @params {IReqIntelligenceNews} 新闻类型（STORY 爬虫新闻、NOTICE 公告消息、BRIEF 简报、REPORT 研报、WEEKLY_REPORT 周报、EXPERT_POST 专栏）
 * @return {Promise}
 */
export const fecthIntelligenceNews = (
  params: IReqIntelligenceNews,
  headers?: ISSRHeaders
): Promise<IRes> => {
  // @ts-ignore
  return axiosInstance.get(api.intelligence.article, { params, headers }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 新闻详情
 * @params {IReqIntelligenceDetails}
 * @return {Promise}
 */
export const fecthIntelligenceNewsDetail = (
  params: IReqIntelligenceDetails,
  headers?: ISSRHeaders
): Promise<IRes> => {
  return axiosInstance
    .get(api.intelligence.articleDetail.replace(/{id}/, params.id), { headers })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 专栏作家列表
 * @params {IReqExpertAuthorList}
 * @return {Promise}
 */
export const fecthExpertAuthorList = (
  params: IReqExpertAuthorList,
  headers?: ISSRHeaders
): Promise<IRes> => {
  return axiosInstance.get(api.intelligence.expert.authorList, { params, headers }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 专栏作家详情信息
 * @params {IReqExpertAuthorDetail}
 * @return {Promise}
 */
export const fecthExpertAuthorDetail = (
  params: IReqExpertAuthorDetail,
  headers?: ISSRHeaders
): Promise<IRes> => {
  return axiosInstance
    .get(api.intelligence.expert.authorDetail.replace(/{id}/, params.id), { headers })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 关注专栏作家
 * @params {IReqExpertFollow}
 * @return {Promise}
 */
export const followExpert = (params: IReqExpertFollow): Promise<IRes> => {
  return axiosInstance.post(api.intelligence.expert.follow, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 反馈通知
 * @params {IReqExpertFeedback}
 * @return {Promise}
 */
export const sendMessage = (params: IReqExpertFeedback): Promise<IRes> => {
  return axiosInstance.post(api.intelligence.expert.feedback, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取关注关系
 * @params {IReqExpertArticleTopList}
 * @return {Promise}
 */
export const fecthExpertAuthorFollowed = (params: IReqExpertAuthorFollowed): Promise<IRes> => {
  return axiosInstance
    .get(api.intelligence.expert.followed.replace(/{followId}/, params.followId))
    .then((res) => {
      return res?.data;
    });
};
/*-------------------- end 新闻相关 -------------------- */

/* -------------------- 理财相关 -------------------- */

/**
 * @description: 赎回
 * @params {IReqAvailable}
 * @return {Promise}
 */
export const redeem = (params: IReqRedeem): Promise<IRes> => {
  return axiosInstance.post(api.savings.flexible.redeem, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 申购/追加
 * @params {IReqSubscribe}
 * @return {Promise}
 */
export const subscribe = (params: IReqSubscribe): Promise<IRes> => {
  return axiosInstance.post(api.savings.flexible.subscribe, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取现货账户的该币种可用数量, 可以一次传多个币种，按map返回
 * @params {IReqSpotBalance}
 * @return {Promise}
 */
export const availableSpots = (params: IReqSpotBalance): Promise<IRes> => {
  return axiosInstance.get(api.balances, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 活期理财申购前获取现货账户的该币种可用数量
 * @params {IReqRedeemAvailable}
 * @return {Promise}
 */
export const availableRedeem = (params: IReqRedeemAvailable): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.available, { params }).then((res) => {
    return res?.data;
  });
};

// 我的理财余额（my savings balance）
export const yieldBalance = (): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.yieldBanlance).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 活期理财产品列表
 * @params {IReqflexibleProducts}
 * @return {Promise}
 */
export const fetchYieldProducts = (
  params: IReqFlexibleProducts,
  headers?: ISSRHeaders | object
): Promise<IFlexibleIResponse> => {
  return axiosInstance.get(api.savings.flexible.products, { params, headers }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 活期理财指定产品
 * @params {IReqflexibleProducts}
 * @return {Promise}
 */
export const yieldList = (params: { currencies: string }): Promise<IYieldFlexListRes> => {
  return axiosInstance.get(api.savings.flexible.list, { params }).then((res) => {
    return res.data;
  });
};

/**
 * @description: 申购/赎回历史记录
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const yieldHistory = (params: IReqFlexibleHistory): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.history, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 自动申购 开启/关闭
 * @params {IReqAutoSubscribe}
 * @return {Promise}
 */
export const autoSubscribe = (params: IReqAutoSubscribe): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.autoSubscribe, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 利息滚投 开启/关闭
 * @params {IReqAutoCompound}
 * @return {Promise}
 */
export const changeInterestType = (params: IReqAutoCompound): Promise<IRes> => {
  return axiosInstance.post(api.savings.flexible.changeInterestType, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 利息买币 开启/关闭
 * @params {IReqInterestTrading}
 * @return {Promise}
 */
export const interestTrading = (params: IReqInterestTrading): Promise<IRes> => {
  return axiosInstance.get(api.savings.flexible.interestTrading, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取利息买币的最新一条信息
 * @params {productId}
 * @return {Promise}
 */
export const getInterestTradingInfo = (productId: string): Promise<IRes> => {
  return axiosInstance
    .get(api.savings.flexible.interestTradingInfo, { params: { productId } })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 定投产品列表
 * @params {IReqAutoProducts}
 * @return {Promise}
 */
export const autoInvestList = (
  params: IReqAutoProducts,
  headers?: ISSRHeaders
): Promise<IInvestmentIResponse> => {
  return axiosInstance.get(api.savings.autoInvest.products, { params, headers }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定投产品详情
 * @params {IReqAutoProductsDetail}
 * @return {Promise}
 */
export const autoInvestDetail = (params: IReqAutoProductsDetail): Promise<IAutoInvestDetailRes> => {
  return axiosInstance
    .get(api.savings.autoInvest.productDetail.replace(/{id}/, params.id))
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 我的定投合约列表
 * @params {IReqAutoProducts}
 * @return {Promise}
 */
export const myAutoInvestList = (params: IReqAutoProducts): Promise<IRes> => {
  return axiosInstance.get(api.savings.autoInvest.contracts, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 我的定投合约详情
 * @params {IReqMyAutoProductsDetail}
 * @return {Promise}
 */
export const myAutoInvestDetail = (params: IReqMyAutoProductsDetail): Promise<IRes> => {
  return axiosInstance
    .get(api.savings.autoInvest.contractDetail.replace(/{contractId}/, params.contractId))
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 我的定投产品列表历史
 * @params {IReqAutoProductsHistory}
 * @return {Promise}
 */
export const myAutoInvestHistoryList = (params: IReqAutoProductsHistory): Promise<IRes> => {
  return axiosInstance.get(api.savings.autoInvest.history, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取btc ytd
 * @params
 * @return {Promise}
 */
export const getBtcYtd = (params: { from: number; to: number }): Promise<IBtcYtdRes> => {
  return axiosInstance.get(api.savings.autoInvest.ytdTrend, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 是否存在币种策略合约
 * @params {IReqMyStrategyDetail}
 * @return {Promise}
 */
export const getMyStrategyDetail = (
  params: IReqMyStrategyDetail
): Promise<IAutoInvestStrategyRes> => {
  return axiosInstance.get(api.savings.autoInvest.strategy, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 策略的起购额列表
 * @params {productId: string}
 * @return {Promise}
 */
export const autoInvestMinAmount = (params: {
  productId: string;
}): Promise<IAutoInvestMinAmountRes> => {
  return axiosInstance
    .get(api.savings.autoInvest.minAmount.replace(/{productId}/, params.productId))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 定投支持的币种列表
 * @params {IReqAutoProducts}
 * @return {Promise}
 */
export const autoInvestCurrencyScope = (): Promise<IAutoInvestCurrenciesRes> => {
  return axiosInstance.get(api.savings.autoInvest.currencyScope).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 申购/追加定投产品
 * @params {IReqAddAutomaticInvestmentPlan}
 * @return {Promise}
 */
export const addAutomaticInvestmentPlan = (params: IReqAutoInvestSubscribe): Promise<IRes> => {
  return axiosInstance
    .post(api.savings.autoInvest.subscribe, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 终止定投产品
 * @params {IReqCancelAutomaticInvestmentPlan}
 * @return {Promise}
 */
export const cancelAutomaticInvestmentPlan = (
  params: IReqCancelAutomaticInvestmentPlan
): Promise<IRes> => {
  return axiosInstance.post(api.savings.autoInvest.stop, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 开启/关闭自动转活期
 * @params {contractId: string}
 * @return {Promise}
 */
export const autoInvestFinancing = (params: {
  autoFinancing: boolean;
  autoStaking: boolean;
  contractId: string;
  verifyCode?: string; //验证码
}): Promise<IRes> => {
  return axiosInstance.post(api.savings.autoInvest.autoFinancing, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 定期申购历史记录
 * @params {IReqFixedHistory}
 * @return {Promise}
 */
export const fixedYieldHistory = (params: IReqFixedHistory): Promise<IRes> => {
  return axiosInstance.get(api.savings.fixed.history, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: staking历史记录
 * @params {IReqStakingHistory}
 * @return {Promise}
 */
export const stakingYieldHistory = (params: IReqStakingHistory): Promise<IRes> => {
  return axiosInstance.get(api.savings.staking.history, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: Staking产品列表
 */
export const stakingProductsList = (
  params: IReqStakingProducts,
  headers?: ISSRHeaders
): Promise<IStakingListRes> => {
  return axiosInstance.get(api.savings.staking.productList, { params, headers }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: staking 获取客户已申购额度,计算个人最大申购额的时候使用
 */
export const availableStakingQuot = (params: { productId: string }): Promise<IStakingQuotaRes> => {
  return axiosInstance.get(api.savings.staking.quot, { params }).then((res) => {
    return res.data;
  });
};

/**
 * @description: staking申购/追加
 */
export const stakingSubscribe = (params: IStakingReqSubscribe): Promise<IRes> => {
  return axiosInstance.post(api.savings.staking.subscribe, params).then((res) => {
    return res.data;
  });
};

/**
 * @description: 赎回
 */
export const stakingRedeem = (params: IReqRedeem): Promise<IRes> => {
  return axiosInstance.post(api.savings.staking.redeem, params).then((res) => {
    return res.data;
  });
};

/**
 * @description: 修改利息质押
 */
export const stakingSwitchAutoRestake = (params: {
  contractId: string;
  interestType: number;
  verifyCode?: string;
}): Promise<IRes> => {
  return axiosInstance.post(api.savings.staking.switchAutoRestake, params).then((res) => {
    return res.data;
  });
};

/**
 * @description: 定期产品列表
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const fixedProductsList = (
  params: IReqFixedProductsList,
  headers?: ISSRHeaders
): Promise<IFixedIResponse> => {
  return axiosInstance.get(api.savings.fixed.productList, { params, headers }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定期产品详情
 * @params {IReqFixedProductsDetails}
 * @return {Promise}
 */
export const getFixedProductsDetail = (params: IReqFixedProductsDetails): Promise<IRes> => {
  return axiosInstance
    .get(api.savings.fixed.productDetail.replace(/{id}/, params.id))
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 申购定期产品
 * @params {IReqSubscribeFixedProducts}
 * @return {Promise}
 */
export const subscribeFixedProducts = (params: IReqSubscribeFixedProducts): Promise<IRes> => {
  return axiosInstance.post(api.savings.fixed.subscribe, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定期持仓合约列表
 * @params {IReqFixedContractsList}
 * @return {Promise}
 */
export const getFixedContractsList = (params: IReqFixedContractsList): Promise<IRes> => {
  return axiosInstance.get(api.wallet.yieldFixedContracts, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定期持仓合约详情
 * @params {IReqFixedContractsDetails}
 * @return {Promise}
 */
export const getFixedContractsDetail = (params: IReqFixedContractsDetails): Promise<IRes> => {
  return axiosInstance
    .get(api.wallet.yieldFixedContractsDetail.replace(/{id}/, params.id))
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 撤销定期申购
 * @params {IReqFixedCancelSubscription}
 * @return {Promise}
 */
export const fixedCancelSubscription = (params: IReqFixedCancelSubscription): Promise<IRes> => {
  return axiosInstance.delete(api.wallet.yieldFixedCancelSubscription, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 定期理财到期自动转活期 / 定期理财到期自动购买下期产品
 * @params {IReqFixedTransferToFlexible}
 * @return {Promise}
 */
export const fixedTransferToFlexible = (params: IReqFixedTransferToFlexible): Promise<IRes> => {
  return axiosInstance.post(api.wallet.yieldFixedTransferToFlexible, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: staking持仓合约列表
 * @params {IReqStakingContractsList}
 * @return {Promise}
 */
export const getStakingContractsList = (params: IReqStakingContractsList): Promise<IRes> => {
  return axiosInstance.get(api.wallet.yieldStakingContracts, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: staking持仓合约详情
 * @params {IReqStakingContractsDetails}
 * @return {Promise}
 */
export const getStakingContractsDetail = (params: IReqStakingContractsDetails): Promise<IRes> => {
  return axiosInstance
    .get(api.wallet.yieldStakingContractsDetail.replace(/{id}/, params.id))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 新定期申购历史记录
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const newFixedYieldHistory = (params: IReqFixedHistory): Promise<IYieldFixedHistoryRes> => {
  return axiosInstance.get(api.savings.newFixed.history, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 新定期产品列表
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const newFixedProductsList = (
  params: IReqFixedProductsList
): Promise<IYieldNewFixedListRes> => {
  return axiosInstance.get(api.savings.newFixed.productList, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 新定期产品详情
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const getNewFixedProductsDetail = (params: {
  id: string;
}): Promise<IYieldNewFixedDetailRes> => {
  return axiosInstance
    .get(api.savings.newFixed.productDetail.replace(/{id}/, params.id))
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 新定期产品申购
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const subscribeNewFixedProducts = (params: IReqNewSubscribeFixedProducts): Promise<IRes> => {
  return axiosInstance.post(api.savings.newFixed.subscribe, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 新定期更新续期选择
 * @params {IReqFlexibleHistory}
 * @return {Promise}
 */
export const newFixedProductsConfig = (params: IReqNewFixedConfig): Promise<IRes> => {
  return axiosInstance.post(api.savings.newFixed.config, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 新定期持仓合约列表
 * @params {IReqFixedContractsList}
 * @return {Promise}
 */
export const getNewFixedContractsList = (params: IReqNewFixedContractsList): Promise<IRes> => {
  return axiosInstance.get(api.savings.newFixed.yieldFixedContracts, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 新定期持仓合约详情
 * @params {IReqFixedContractsList}
 * @return {Promise}
 */
export const getNewFixedContractDetail = (params: {
  id: string;
  showPreviousContacts: boolean;
}): Promise<IRes> => {
  return axiosInstance
    .get(api.savings.newFixed.yieldFixedContractDetail, { params })
    .then((res) => {
      return res?.data;
    });
};

/* -------------------- end 理财相关 -------------------- */

/* -------------------- 策略相关 -------------------- */

/**
 * @description: 策略列表
 * @params {IReqStrategyList}
 * @params
 * @return {Promise}
 */
export const getStrategyList = (params: IReqStrategyList): Promise<any> => {
  return new Promise((resolve) => {
    axiosInstance
      .get(api.strategies.strategyList, {
        params
      })
      .then((res) => {
        resolve(res?.data);
      });
  });
};

/**
 * @description: 策略详情
 * @params {IReqStrategyDetail}
 * @return {Promise}
 */
export const getStrategyDetail = (
  params: IReqStrategyDetail,
  headers?: ISSRHeaders
): Promise<IRes> => {
  return axiosInstance
    .get(api.strategies.strategyDetail.replace(/{strategyId}/, params.strategyId), { headers })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 策略申购
 * @params {IReqStrategyApply}
 * @return {Promise}
 */
export const subscribeStrategy = (params: IReqStrategyApply): Promise<IRes> => {
  return axiosInstance.post(api.strategies.strategyApply, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 策略赎回
 * @params {IReqStrategyRedeem}
 * @return {Promise}
 */
export const redeemStrategy = (params: IReqStrategyRedeem): Promise<IRes> => {
  return axiosInstance.post(api.strategies.strategyRedeem, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 撤销策略申赎
 * @params {IReqStrategyOrderCancel}
 * @return {Promise}
 */
export const cancelStrategy = (params: IReqStrategyOrderCancel): Promise<IRes> => {
  return axiosInstance
    .delete(api.strategies.strategyOrderCancel.replace(/{orderId}/, params.orderId))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 策略申购赎回历史列表
 * @params {IReqStrategyList}
 * @return {Promise}
 */
export const getStrategyOrderList = (params: IReqStrategyList): Promise<IRes> => {
  return axiosInstance.get(api.strategies.strategyOrderList, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 策略申购赎回历史详情
 * @params {IReqStrategyOrderDetail}
 * @return {Promise}
 */
export const getStrategyOrderDetail = (params: IReqStrategyOrderDetail): Promise<IRes> => {
  return axiosInstance
    .get(api.strategies.strategyOrderDetail.replace(/{orderId}/, params.orderId))
    .then((res) => {
      return res?.data;
    });
};

/* -------------------- end 策略相关 -------------------- */

/* -------------------- ventures相关 -------------------- */

/**
 * @description: 风投列表
 * @params {IReqVentureList}
 * @return {Promise}
 */
export const getVentureList = (params: IReqVentureList): Promise<IRes> => {
  return axiosInstance.get(api.ventures.ventureList, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 风投详情
 * @params {IReqVentureDetail}
 * @return {Promise}
 */
export const getVentureDetail = (
  params: IReqVentureDetail,
  headers?: ISSRHeaders
): Promise<IRes> => {
  return axiosInstance
    .get(api.ventures.ventureDetail.replace(/{ventureInfoId}/, params.ventureInfoId), { headers })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 风投申购赎回历史列表
 * @params {IReqVentureList}
 * @return {Promise}
 */
export const getVentureOrderList = (params: IReqVentureList): Promise<IRes> => {
  return axiosInstance.get(api.ventures.ventureOrderList, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 股权出售意向
 * @params {IReqVentureApply}
 * @return {Promise}
 */
export const ventureShares = (params: IReqVentureShares): Promise<IRes> => {
  return axiosInstance.post(api.ventures.ventureShares, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 风投申购
 * @params {IReqVentureApply}
 * @return {Promise}
 */
export const subscribeVenture = (params: IReqVentureApply): Promise<IRes> => {
  return axiosInstance.post(api.ventures.ventureApply, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 风投赎回
 * @params {IReqVentureRedeem}
 * @return {Promise}
 */
export const redeemVenture = (params: IReqVentureRedeem): Promise<IRes> => {
  return axiosInstance.post(api.ventures.ventureRedeem, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询指定风投产品已提交的赎回意向总金额
 * @params productId: string
 * @return {Promise}
 */
export const getVentureIntentionAmount = (productId: string): Promise<IRes> => {
  return axiosInstance
    .get(api.ventures.ventureIntentionAmount.replace(/{product-id}/, productId), {
      params: { productId }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询所有风投产品持仓情况
 * @params {RListVenturePositionOrder}
 * @return {Promise}
 */
export const getVenturePosition = (): Promise<RListVenturePositionOrder> => {
  return axiosInstance.get(api.ventures.venturePosition).then((res) => {
    return res?.data;
  });
};

/* -------------------- end ventures相关 -------------------- */

/* -------------------- start structured -------------------- */

/**
 * @description: 结构化产品列表
 * @params
 * @return {Promise}
 */
export const getStructuredSpecificList = (
  params: IReqStructuredSpeList
): Promise<IStructuredListRes> => {
  return axiosInstance
    .get(
      api.structured.specificList
        .replace(/{underlying}/, params.underlying)
        .replace(/{quote}/, params.quote)
        .replace(/{type}/, params.type),
      { params }
    )
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 结构化产品详情
 * @params
 * @return {Promise}
 */
export const getStructuredDetail = (params: {
  underlying: string;
  quote: string;
  type: string;
  id: string;
}): Promise<IRes> => {
  return axiosInstance
    .get(api.structured.productDetail.replace(/{id}/, params.id), {
      params: { underlying: params?.underlying, quote: params?.quote, type: params?.type }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 结构化产品underlying
 * @params
 * @return {Promise}
 */
export const getStructuredUnderlying = (): Promise<IStructuredListRes> => {
  return axiosInstance.get(api.structured.underlying).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 结构化产品详情->获取订单ID
 * @params
 * @return {Promise}
 */
export const getStructuredOrderId = (): Promise<IRes> => {
  return axiosInstance.get(api.structured.getOrderId).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 结构化产品详情->下单
 * @params
 * @return {Promise}
 */
export const structuredPlaceOrder = (params: IStructuredOrderModel): Promise<IRes> => {
  return axiosInstance
    .post(api.structured.order, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取订单详情
 * @params
 * @return {Promise}
 */
export const getStructuredOrderDetail = (params: string): Promise<IStructuredDetailRes> => {
  return axiosInstance.get(api.structured.orderDetail.replace(/{orderId}/, params)).then((res) => {
    return res?.data;
  });
};

/**
 * @description: structured 订单列表
 * @params {IReqStructuredList}
 * @return {Promise}
 */
export const fetchStructuredHistory = (params: IReqStructuredList): Promise<IRes> => {
  return axiosInstance.get(api.structured.history, { params }).then((res) => {
    return res?.data;
  });
};

/* -------------------- end structured -------------------- */

/* -------------------- start 冷钱包相关 ------------------------- */
/**
 * @description: 冷钱包
 * @params
 * @return {Promise}
 */
export const getWalletVaultAccounts = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.vault.list).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取币种的资产配置
 * @params
 * @return {Promise}
 */
export const getVaultAssetCurrencyConfig = (params: IWalletAssetsConfig): Promise<IRes> => {
  return axiosInstance.get(api.wallet.vault.assetCurrencyConfig, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: GET 获取已经生成的充币地址
 * @params
 * @return {Promise}
 */
export const showVaultDepositAddress = (params: IWalletVaultDepositAddress): Promise<IRes> => {
  return axiosInstance
    .get(api.wallet.vault.showDepositAddress.replace(/{currency}/, params.currency), { params })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 冷钱包充币
 * @params IReqVaultDepositWithdraw
 * @return {Promise}
 */
export const vaultWalletDeposit = (
  params: IReqVaultDepositWithdraw,
  headers: IReqSecurityVerifyHeader
): Promise<IRes> => {
  const header = { ...headers, "Content-Type": "application/json" };
  return axiosInstance
    .post(api.wallet.vault.deposit, params, {
      headers: header
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 冷钱包提币
 * @params
 * @return {Promise}
 */
export const vaultWalletWithdraw = (
  params: IReqVaultDepositWithdraw,
  headers: IReqSecurityVerifyHeader
): Promise<IRes> => {
  const header = { ...headers, "Content-Type": "application/json" };
  return axiosInstance
    .post(api.wallet.vault.withdraw, params, {
      headers: header
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取冷钱包充提历史
 * @params
 * @return {Promise}
 */
export const vaultDepositWithdrawHistory = (params: IReqWalletHistory): Promise<IRes> => {
  return axiosInstance.get(api.wallet.vault.depositWithdrawHistory, { params }).then((res) => {
    return res?.data;
  });
};
/* -------------------- end 冷钱包相关 ------------------------- */

/* -------------------- 钱包相关 -------------------- */

/**
 * @description: 获取钱包信息
 * @params
 * @return {Promise}
 */
export const getWalletInfo = (params: IWalletInfo, url?: string): Promise<any> => {
  return axiosInstance.get(url ?? api.wallet.walletInfo, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 现货账户
 * @params
 * @return {Promise}
 */
export const getWalletSpotAccounts = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.spot).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 理财账户
 * @params
 * @return {Promise}
 */
export const getWalletYileldAccounts = (params: IWalletAccounts): Promise<IRes> => {
  return axiosInstance.get(api.wallet.yield, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 结构化理财账户
 * @params
 * @return {Promise}
 */
export const getWalletStructuredAccounts = (params: IWalletAccounts): Promise<IRes> => {
  return axiosInstance.get(api.wallet.structured, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 结构化理财持仓详情
 * @params
 * @return {Promise}
 */
export const getWalletStructuredAccountsDetail = (
  params: IReqStructuredAccountList
): Promise<IRes> => {
  return axiosInstance.get(api.wallet.structuredAccountsDetail, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 策略账户
 * @params
 * @return {Promise}
 */
export const getWalletStrategiesAccounts = (params: IWalletAccounts): Promise<IRes> => {
  return axiosInstance.get(api.wallet.strategies, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 风投账户
 * @params
 * @return {Promise}
 */
export const getWalletVenturesAccounts = (params: IWalletAccounts): Promise<IRes> => {
  return axiosInstance.get(api.wallet.ventures, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 风投账户Updates
 * @params IReqVenturesUpdates
 * @return {Promise}
 */
export const getWalletVentureUpdates = (params: IReqVenturesUpdates): Promise<IRes> => {
  return axiosInstance.get(api.wallet.updates, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 风投账户Updates detail
 * @params IReqVenturesUpdates
 * @return {Promise}
 */
export const getWalletVentureUpdatesDetail = (params: IReqVenturesUpdatesDetail): Promise<IRes> => {
  return axiosInstance.get(api.wallet.updatesDetail, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: venture Updates read
 * @params
 * @return {Promise}
 */
export const walletVentureUpdatesRead = (params: { id: string }): Promise<IRes> => {
  return axiosInstance.put(api.wallet.updatesRead.replace(/{id}/, params.id)).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取币种的多链列表
 * @params
 * @return {Promise}
 */
export const getAssetCurrencyConfig = (params?: IWalletAssetsConfig): Promise<IRes> => {
  return axiosInstance.get(api.wallet.assetCurrencyConfig, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 电子货币充值地址，没有则生成地址
 * @params
 * @return {Promise}
 */
export const getDepositAddress = (params: IWalletDepositAddress): Promise<IRes> => {
  return axiosInstance
    .get(api.wallet.depositAddress.replace(/{currency}/, params.currency), { params })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 电子货币提取
 * @params {IWalletWithdraw}
 * @return {Promise}
 */
export const applyWithdraw = (
  params: IWalletWithdraw,
  headers: IReqSecurityVerifyHeader
): Promise<IRes> => {
  const header = { ...headers, "Content-Type": "application/json" };
  return axiosInstance.post(api.wallet.applyWithdraw, params, { headers: header }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 判断是否可以出金
 * @return {Promise}
 */
export const preWithdraw = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.preWithdraw).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 电子货币充提历史
 * @params {IWalletWithdraw}
 * @return {Promise}
 */
export const depositWithdrawHistory = (params: IReqWalletHistory): Promise<IRes> => {
  return axiosInstance.get(api.wallet.depositWithdrawHistory, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询用户是否开启提币白名单等信息
 * @return {Promise}
 */
export const getWithdrawEnableWhiteInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.withdrawEnableWhiteInfo).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 提币地址列表
 * @params {IReqWalletWithdrawAddress}
 * @return {Promise}
 */
export const getWithdrawAddressList = (params: IReqWalletWithdrawAddress): Promise<IRes> => {
  return axiosInstance.get(api.wallet.withdrawAddressList, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 提币白名单历史记录列表
 * @params {IReqWithdrawWhiteListHistory}
 * @return {Promise}
 */
export const getWithdrawWhiteListHistory = (
  params: IReqWithdrawWhiteListHistory
): Promise<IRes> => {
  return axiosInstance.get(api.wallet.withdrawWhiteListHistory, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 添加地址查询来源
 * @params {}
 * @return {Promise}
 */
export const getWithdrawAddressOrigin = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.withdrawAddressSource).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 添加提币地址
 * @params {IReqAddWithdrawAddress}
 * @return {Promise}
 */
export const addWithdrawAddress = (
  params: IReqAddWithdrawAddress,
  headers: IReqSecurityVerifyHeader
): Promise<IRes> => {
  const header = { ...headers, "Content-Type": "application/json" };
  return axiosInstance
    .post(api.wallet.withdrawAddAddress, params, {
      headers: header
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 校验提币地址格式是否正确
 * @params {IReqWalletCheckWithdrawAddress}
 * @return {Promise}
 */
export const checkWithdrawAddressFormat = (
  params: IReqWalletCheckWithdrawAddress
): Promise<IRes> => {
  return axiosInstance.get(api.wallet.withdrawCheckAddress, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description:提币白名单开关
 * @params {IReqWalletWithdrawWhiteListOnAndOff}
 *
 * @return {Promise}
 */
export const withdrawWhiteListOnAndOff = (
  params: IReqWalletWithdrawWhiteListOnAndOff,
  headers: IReqSecurityVerifyHeader
): Promise<IRes> => {
  const header = { ...headers, "Content-Type": "application/json" };
  return axiosInstance
    .post(api.wallet.withdrawWhiteListOnAndOff, params, {
      headers: header
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 删除地址
 * @params {IReqFixedCancelSIReqWalletWithdrawAddressDeleteubscription}
 * @return {Promise}
 */
export const deleteWithdrawAddress = (params: IReqWalletWithdrawAddressDelete): Promise<IRes> => {
  return axiosInstance
    .delete(api.wallet.withdrawDelete.replace(/{addressId}/, params.addressId))
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description:更新地址为白名单地址 and 移除
 * @params {IReqWalletWithdrawWhiteListUpdate}
 * @return {Promise}
 */
export const withdrawWhiteList = (
  params: IReqWalletWithdrawWhiteListUpdate,
  headers: IReqSecurityVerifyHeader
): Promise<IRes> => {
  const header = { ...headers, "Content-Type": "application/json" };
  return axiosInstance
    .put(api.wallet.withdrawWhiteList, params, {
      headers: header
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description:更新地址为普通地址
 * @params {IReqWalletWithdrawWhiteListUpdate}
 * @return {Promise}
 */
export const withdrawNormalList = (params: IReqWalletWithdrawWhiteListUpdate): Promise<IRes> => {
  return axiosInstance
    .put(api.wallet.withdrawNormalList, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

//circle
/**
 * @description: 获取银行账户信息
 * @params
 * @return {Promise}
 */
export const getBankAccount = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.bankAccount).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取circle支持银行列表
 * @params
 * @return {Promise}
 */
export const getBankList = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.bankList).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 绑定、修改circle银行账户
 * @params {IWalletAddBankAccount}
 * @return {Promise}
 */
export const addCircleAccount = (params: IWalletAddBankAccount): Promise<IRes> => {
  return axiosInstance
    .post(api.wallet.addCircleAccount, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 获取circle入金信息
 * @params
 * @return {Promise}
 */
export const getCircleDepositInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.circleDepositInfo).then((res) => {
    return res?.data;
  });
};

/**
 * @description: circle usdc提取
 * @params {IWalletWithdrawCircle}
 * @return {Promise}
 */
export const withdrawCircle = (
  params: IWalletWithdrawCircle,
  headers: IReqSecurityVerifyHeader
): Promise<IRes> => {
  const header = { ...headers, "Content-Type": "application/json" };
  return axiosInstance
    .post(api.wallet.circleWithdraw, params, {
      headers: header
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 获取circle历史
 * @params {IWalletFiatHistory}
 * @return {Promise}
 */
export const getCircleHistory = (params: IReqWalletFiatHistory): Promise<IRes> => {
  return axiosInstance.get(api.wallet.circleHistory, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取circle入金信息
 * @params
 * @return {Promise}
 */
export const getCircleLimit = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.circleLimit).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 判断资产管理费是否可以withdraw
 * @params {}
 * @return {Promise}
 */
export const amfIsEnough = (): Promise<IRes> => {
  return axiosInstance.get(api.wallet.amfIsEnough).then((res) => {
    return res?.data;
  });
};
/**
 * @description: withdraw手动扣除资产管理费
 * @params {}
 * @return {Promise}
 */
export const amfDeduct = (): Promise<IRes> => {
  return axiosInstance.post(api.wallet.amfDeduct).then((res) => {
    return res?.data;
  });
};

/**
 * @description: otc历史
 * @return {Promise}
 */
export const otcHistory = (params: {
  nextToken?: string | null;
  limit: number;
}): Promise<IOtcHistoryListRes> => {
  return axiosInstance.get(api.wallet.otcOrders, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: otc意向
 * @return {Promise}
 */
export const otcApply = (params: {
  currency: typeof REFERENCE_CURRENCY | typeof BENCHMARK_CURRENCY;
  amount: number;
}): Promise<IRes> => {
  return axiosInstance.post(api.wallet.otcIntentionOrder, params, {}).then((res) => {
    return res?.data;
  });
};

/* -------------------- end 钱包相关 -------------------- */

/* -------------------- 个人中心相关 -------------------- */

/**
 * @description: 获取kyc
 * @params {}
 * @return {Promise}
 */
export const fecthKycInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.kyc.get).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取kyc风险等级
 * @params {}
 * @return {Promise}
 */
export const getKycRiskLevel = (
  params: IReqKycInstitutions | IReqKycIndividuals
): Promise<IRes> => {
  return axiosInstance
    .post(api.kyc.risk, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 提交机构/个人kyc
 * @params {IReqKycInstitutions ｜ IReqKycIndividuals}
 * @return {Promise}
 */
export const submitKyc = (params: IReqKycInstitutions | IReqKycIndividuals): Promise<IRes> => {
  return axiosInstance
    .post(api.kyc.submit, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * 绑定手机
 * @param params
 * @param headers
 * @return {Promise}
 */
export const bindPhone = (params: IReqBindPhone, headers?: { jwtToken: string }): Promise<IRes> => {
  const header = { ...headers, "Content-Type": "application/json" };
  return axiosInstance
    .post(api.customer.phoneOperate, params, {
      headers: header
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * 换绑手机
 * @param params
 * @return {Promise}
 */
export const changePhone = (params: IReqChangePhone): Promise<IRes> => {
  return axiosInstance
    .put(api.customer.phoneOperate, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * 解绑手机
 * @param params
 * @param headers
 * @return {Promise}
 */
export const unbindPhone = (
  params: IReqUnbindPhone,
  headers: IReqSecurityVerifyHeader
): Promise<IRes> => {
  return axiosInstance.delete(api.customer.phoneOperate, { params, headers }).then((res) => {
    return res?.data;
  });
};

/**
 * 获取手机短信验证码
 * @param params
 * @param headers
 * @return {Promise}
 */
export const getPhoneCode = (
  params: IReqGetPhoneCode,
  headers?: { jwtToken: string }
): Promise<IRes> => {
  return axiosInstance.get(api.customer.getPhoneCode, { params, headers }).then((res) => {
    return res?.data;
  });
};

/**
 * 获取手机验证码 无需登录 （目前只有2fa验证时才会调用该接口，就是手机号登录）
 * @param params
 * @return {Promise}
 */
export const getPhoneCodeNoLogin = (params: IReqGetPhoneCodeNoLogin): Promise<IRes> => {
  return axiosInstance
    .get(api.customer.getPhoneCodeNoLogin.replace(/{phone}/, params.phone), { params })
    .then((res) => {
      return res?.data;
    });
};

/**
 * 安全验证前置校验
 * @param headers
 * @return {Promise}
 */
export const securityVerify = (headers: IReqSecurityVerifyHeader): Promise<IRes> => {
  const header = { ...headers, "Content-Type": "application/json" };
  return axiosInstance.post(api.customer.securityVerify, {}, { headers: header }).then((res) => {
    return res?.data;
  });
};

/**
 * 获取用户是否能做某个事件
 * @param params: IReqUserEventCheck
 * @return {Promise}
 */
export const userEventCheck = (params: IReqUserEventCheck): Promise<IRes> => {
  return axiosInstance.get(api.customer.userEventCheck, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * 获取已生成的月结账单列表
 * @return {Promise}
 */
export const getReportList = (params: IReqReportList): Promise<IRes> => {
  return axiosInstance.get(api.customer.reportList, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * 下载已生成的月结账单
 * @param params: IReqReport
 * @return {Promise}
 */
export const downloadReport = (params: IReqReport): Promise<any> => {
  return axiosInstance
    .get(api.customer.report, {
      responseType: "arraybuffer",
      params,
      headers: { "Cache-Control": "max-age=86400" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 谷歌解绑
 * @params {IReqGoogleRemove}
 * @return {Promise}
 */
export const googleRemove = (params: IReqGoogleRemove, headers: IReqSecurityVerifyHeader) => {
  return (
    axiosInstance
      // @
      .post(api.customer.removeBind, params, { headers })
      .then((res) => {
        return res?.data;
      })
      .catch((_err) => {})
  );
};
/**
 * @description: 绑定谷歌
 * @params {IReqGoogleBind}
 * @return {Promise}
 */
export const googleBind = (params: IReqGoogleBind): Promise<IRes> => {
  return axiosInstance.post(api.customer.googlebind, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取googlekey
 * @return {Promise}
 */
export const googleGenerateKey = (headers: IReqSecurityVerifyHeader): Promise<IRes> => {
  return axiosInstance.post(api.customer.generateKey, {}, { headers }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取googlekey
 * @return {Promise}
 */
export const isRegistered = (params: IReqIfExist): Promise<IRes> => {
  return axiosInstance.get(api.customer.ifExist, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 查询代理商代客操作授权信息
 * @return {Promise}
 */
export const applyCustomerRole = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.agentAuthorization).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 终止代理商权限
 * @params {IReqStopPartnerRole}
 * @return {Promise}
 */
export const terminatePartnerRole = (params: IReqStopPartnerRole): Promise<IRes> => {
  return axiosInstance.delete(api.customer.agentAuthorization, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 查询子账号信息
 * @return {Promise}
 */
export const getSubInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.subInfo).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 添加子账号
 * @params {IReqAddSub}
 * @return {Promise}
 */
export const inviteSub = (params: IReqAddSub): Promise<IRes> => {
  return axiosInstance.post(api.customer.inviteSub, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 注册子账号
 * @params {IReqRegisterSub}
 * @return {Promise}
 */
export const registerSub = (params: IReqRegisterSub): Promise<IRes> => {
  return axiosInstance.post(api.customer.registerSub, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 终止子账号
 * @params {IReqStopSub}
 * @return {Promise}
 */
export const stopSub = (params: IReqStopSub): Promise<IRes> => {
  return axiosInstance.delete(api.customer.stopSub, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取我的邀请列表
 * @params {IReqMyInvitation}
 * @return {Promise}
 */
export const getMyInvitationList = (params: IReqMyInvitation): Promise<IRes> => {
  return axiosInstance.get(api.customer.myInvitation, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 允许帮客操作开关
 * @params {IReqAllowHelpOperate}
 * @return {Promise}
 */
export const updateAllowHelpOperate = (params: IReqAllowHelpOperate): Promise<IRes> => {
  return axiosInstance.post(api.customer.allowHelpOperate, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询允许帮客操作开关信息
 * @return {Promise}
 */
export const getAllowHelpOperateInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.allowHelpOperate).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 语言切换
 * @params {}
 * @return {Promise}
 */
export const switchLanguage = (): Promise<IRes> => {
  return axiosInstance.post(api.customer.switchLanguage).then((res) => res.data);
};

/* -------------------- end 个人中心相关 -------------------- */

/* -------------------- 客户代理商/管理员相关 --------------------*/

/**
 * @description: 获取代理商邀请码
 * @params {}
 * @return {Promise}
 */
export const fecthInviterInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.inviterInfo).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取客户代理商信息
 * @params {}
 * @return {Promise}
 */
export const getClientInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.client.info).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取客户信息
 * @params {IReqCustomerList}
 * @return {Promise}
 */
export const getCustomerList = (params: IReqCustomerList): Promise<IRes> => {
  return axiosInstance.get(api.client.customerList, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商资产
 * @params {}
 * @return {Promise}
 */
export const getCommissionSummary = (): Promise<IRes> => {
  return axiosInstance.get(api.client.commissionSummary).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取客户资产管理费
 * @params {}
 * @return {Promise}
 */
export const getAMFSummary = (): Promise<IRes> => {
  return axiosInstance.get(api.client.AMFSummary).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取客户资产管理发列表历史
 * @params {IReqCustomerList}
 * @return {Promise}
 */
export const getAMFList = (params: IReqCustomerList): Promise<IRes> => {
  return axiosInstance.get(api.client.AMFList, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 代理商登陆
 * @params {IReqLogin}
 * @return {Promise}
 */
export const agentLogin = (params: IReqLogin, headers?: Record<string, string>): Promise<IRes> => {
  return axiosInstance.post(api.client.agentLogin, params, { headers }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 代理商操作客户
 * @params {IReqClientInfo}
 * @return {Promise}
 */
export const operateCustomer = (params: IReqClientInfo): Promise<IRes> => {
  return axiosInstance.post(api.client.operateCustomer, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 退出代理商操作客户
 * @params {}
 * @return {Promise}
 */
export const cancelOperateCustomer = (): Promise<IRes> => {
  return axiosInstance.delete(api.client.operateCustomer).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 申请代客操作
 * @params {IResClientApply}
 * @return {Promise}
 */
export const applyPartner = (params: IResClientApply): Promise<IRes> => {
  return axiosInstance
    .post(api.client.applyRole, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description:
 * @params {IReqAgentApply}
 * @return {Promise}
 */
export const processApplyRole = (params: IReqAgentApply): Promise<IRes> => {
  return axiosInstance
    .put(api.customer.agentAuthorization, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 修改秘书账号授权类型
 * @params {IReqAgentApply}
 * @return {Promise}
 */
export const modifyAuthorizationType = (params: IReqAuthorizationType): Promise<IRes> => {
  return axiosInstance
    .put(api.customer.modifyAuthorizationType, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 修改子账号授权类型
 * @params {IReqAgentApply}
 * @return {Promise}
 */
export const modifySubAuthorizationType = (params: IReqAuthorizationType): Promise<IRes> => {
  return axiosInstance
    .post(api.customer.modifySubAuthorizationType, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 管理员登陆
 * @params {IReqManagerLogin}
 * @return {Promise}
 */
export const managerLogin = (params: IReqManagerLogin): Promise<IRes> => {
  return axiosInstance.post(api.client.managerLogin, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 管理员操作客户
 * @params {IReqClientInfo}
 * @return {Promise}
 */
export const managerOperate = (params: IReqClientInfo): Promise<IRes> => {
  return axiosInstance.post(api.client.managerOperate, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 退出代理操作客户
 * @params {}
 * @return {Promise}
 */
export const cancelManagerOperate = (): Promise<IRes> => {
  return axiosInstance.delete(api.client.managerOperate).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 用户信息
 * @params {}
 * @return {Promise}
 */
export const getCustomerData = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.customerInfo).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 管理员获取用户信息
 * @params {IReqClientInfo}
 * @return {Promise}
 */
export const getManageCustomerInfo = (params: IReqClientInfo): Promise<IRes> => {
  return axiosInstance
    .get(api.customer.manageInfo.replace(/{customerId}/, params.customerId))
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 修改管理员密码
 * @params {IReqResetPassword}
 * @return {Promise}
 */
export const resetManagerPassword = (params: IReqResetPassword): Promise<IRes> => {
  return axiosInstance.post(api.client.changePassword, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商的客户数据
 * @params {}
 * @return {Promise}
 */
export const getClient = (): Promise<IRes> => {
  return axiosInstance.get(api.client.client).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商返佣数据
 * @params {}
 * @return {Promise}
 */
export const getRevenue = (): Promise<IRes> => {
  return axiosInstance.get(api.client.revenue).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商的客户月度AUM
 * @params {IReqAum}
 * @return {Promise}
 */
export const getAum = (params: IReqAum): Promise<IRes> => {
  return axiosInstance.get(api.client.aum, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商的客户最近活动数据
 * @params {IReqClientRecent}
 * @return {Promise}
 */
export const getRecent = (params: IReqClientRecent): Promise<IRes> => {
  return axiosInstance.get(api.client.recent, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商的客户资产汇总信息
 * @params {IReqClientAssetSummary}
 * @return {Promise}
 */
export const getAssetSummary = (params: IReqClientAssetSummary): Promise<IRes> => {
  return axiosInstance.get(api.client.assetSummary, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商的客户资产汇总信息详情
 * @params { customerId: string }
 * @return {Promise}
 */
export const getAssetSummaryDetail = (params: { customerId: string }): Promise<IRes> => {
  return axiosInstance.get(api.client.assetSummaryDetail, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商的客户事件统计信息
 * @params {IReqClientSessionStatistics}
 * @return {Promise}
 */
export const getSessionStatistics = (params: IReqClientSessionStatistics): Promise<IRes> => {
  return axiosInstance.get(api.client.sessionStatistics, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商的客户事件统计信息
 * @return {Promise}
 */
export const getMonitorTypes = (): Promise<IRes> => {
  return axiosInstance.get(api.client.monitorTypes).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商的客户资产汇总信息
 * @params {IReqClientCustomerAssets}
 * @return {Promise}
 */
export const getAgentCustomerWallet = (params: IReqClientCustomerAssets): Promise<IRes> => {
  return axiosInstance.get(api.client.walletAssets, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商的客户资产分布
 * @params {IReqClientCustomerAssetsAllocation}
 * @return {Promise}
 */
export const getAgentCustomerAssetAllocation = (
  params: IReqClientCustomerAssetsAllocation
): Promise<IRes> => {
  return axiosInstance.get(api.client.assetAllocation, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商客户钱包表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerSpot = (params: IReqClientCustomerWalletList): Promise<IRes> => {
  return axiosInstance.get(api.client.spotAccount, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取代理商客户钱包表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerFlexible = (params: IReqClientCustomerWalletList): Promise<IRes> => {
  return axiosInstance.get(api.client.flexibleAccount, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商客户钱包表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerFixed = (params: IReqClientCustomerWalletList): Promise<IRes> => {
  return axiosInstance.get(api.client.fixedAccount, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商客户钱包表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerStaking = (params: IReqClientCustomerWalletList): Promise<IRes> => {
  return axiosInstance.get(api.client.stakingAccount, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商客户钱包表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerDual = (params: IReqClientCustomerWalletList): Promise<IRes> => {
  return axiosInstance.get(api.client.dualAccount, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商客户钱包表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerAccumulator = (
  params: IReqClientCustomerWalletList
): Promise<IRes> => {
  return axiosInstance.get(api.client.accumulatorTableAccount, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商客户钱包表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerSnowball = (params: IReqClientCustomerWalletList): Promise<IRes> => {
  return axiosInstance.get(api.client.snowballTableAccount, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商客户钱包表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerStrategy = (params: IReqClientCustomerWalletList): Promise<IRes> => {
  return axiosInstance.get(api.client.strategyAccount, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商客户钱包表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerVenture = (params: IReqClientCustomerWalletList): Promise<IRes> => {
  return axiosInstance.get(api.client.ventureAccount, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取代理商客活动记录12个月
 * @params {IReqClientInfo}
 * @return {Promise}
 */
export const getAgentCustomerRecentSessions = (params: IReqClientInfo): Promise<IRes> => {
  return axiosInstance.get(api.client.recentSessions, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取代理商客活动记录历史
 * @params {IReqClientCustomerSessinosHistory}
 * @return {Promise}
 */
export const getAgentCustomerSessionsHistory = (
  params: IReqClientCustomerSessinosHistory
): Promise<IRes> => {
  return axiosInstance.get(api.client.sessionsHistory, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取 venture product list
 * @params {IReqClientProductList}
 * @return {Promise}
 */
export const getCmsVentureList = (params: IReqClientProductList): Promise<IRes> => {
  return axiosInstance.get(api.client.ventureList, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取对当前类型某产品近期的访问者
 * @params {IReqClientRecentVisit}
 * @return {Promise}
 */
export const getCmsVentureRecentVisit = (params: IReqClientRecentVisit): Promise<IRes> => {
  return axiosInstance.get(api.client.ventureRecentVisit, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取近期venture某一产品的意向记录
 * @params {IReqClientVentureInterested}
 * @return {Promise}
 */
export const getCmsVentureInterested = (params: IReqClientVentureInterested): Promise<IRes> => {
  return axiosInstance.get(api.client.ventureInterested, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取近期venture某一产品的申购记录
 * @params {IReqClientSubscriptions}
 * @return {Promise}
 */
export const getCmsVentureSubscriptions = (params: IReqClientSubscriptions): Promise<IRes> => {
  return axiosInstance.get(api.client.ventureSubscriptions, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询产品的申购记录用户
 * @params {IReqClientSubscriptions}
 * @return {Promise}
 */
export const getCmsStakingSubscriptions = (params: IReqClientSubscriptions): Promise<IRes> => {
  return axiosInstance.get(api.client.stakingSubscriptions, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取产品持仓AUM及增幅占比等信息
 * @params {IReqClientAumProportion}
 * @return {Promise}
 */
export const getCmsVentureAumProportion = (params: IReqClientAumProportion): Promise<IRes> => {
  return axiosInstance.get(api.client.ventureAumProportion, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 分页查询用户持仓占比、产品访问时间等信息
 * @params {IReqClientCustomerAumProportion}
 * @return {Promise}
 */
export const getCmsVentureCustomerAumProportion = (
  params: IReqClientCustomerAumProportion
): Promise<IRes> => {
  return axiosInstance.get(api.client.ventureCustomerAumProportion, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询产品的业务监控
 * @params {IReqClientListProductBusiness}
 * @return {Promise}
 */
export const getCmsListProductBusiness = (params: IReqClientListProductBusiness): Promise<IRes> => {
  return axiosInstance.get(api.client.listProductBusiness, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询代理商下属客户Staking产品按月统计的Aum
 * @params {IReqClientStakingAum}
 * @return {Promise}
 */
export const getCmsStakingAum = (params: IReqClientStakingAum): Promise<IRes> => {
  return axiosInstance.get(api.client.stakingAum, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询代理商下属客户操作Staking产品的事件数（按月统计）
 * @params {IReqClientStakingMonthlyParams}
 * @return {Promise}
 */
export const getCmsStakingEvent = (params: IReqClientStakingMonthlyParams): Promise<IRes> => {
  return axiosInstance.get(api.client.stakingEvent, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询代理商下属客户Staking产品按月统计的持仓人数
 * @params {IReqClientStakingMonthlyParams}
 * @return {Promise}
 */
export const getCmsStakingSubscriber = (params: IReqClientStakingMonthlyParams): Promise<IRes> => {
  return axiosInstance.get(api.client.stakingSubscriber, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 分页查询用户交易订单记录
 * @params {IReqTradeOrdersParams}
 * @return {Promise}
 */
export const getCmsTradeOrders = (params: IReqTradeOrdersParams): Promise<IRes> => {
  return axiosInstance.get(api.client.tradeOrders, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 客户交易分析对比历史增量增幅等信息
 * @params {IReqTradeAnalysisParams}
 * @return {Promise}
 */
export const getCmsTradeAnalysis = (params: IReqTradeAnalysisParams): Promise<IRes> => {
  return axiosInstance.get(api.client.tradeAnalysis, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 客户交易分析对比历史增量增幅等信息
 * @params {IReqTradeVolumeParams}
 * @return {Promise}
 */
export const getCmsTradeVolume = (params: IReqTradeVolumeParams): Promise<IRes> => {
  return axiosInstance.get(api.client.tradeVolume, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询代理商下属客户按月统计的订单交易人数
 * @params {IReqTradeVolumeParams}
 * @return {Promise}
 */
export const getCmsTradeTrader = (params: IReqTradeVolumeParams): Promise<IRes> => {
  return axiosInstance.get(api.client.tradeTrader, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询代理商下属客户按月统计的订单交易事件数
 * @params {IReqTradeVolumeParams}
 * @return {Promise}
 */
export const getCmsTradeEvent = (params: IReqTradeVolumeParams): Promise<IRes> => {
  return axiosInstance.get(api.client.tradeEvent, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询代理商下属客户按月统计的月末现货aum
 * @params {IReqTradeVolumeParams}
 * @return {Promise}
 */
export const getCmsTradeAum = (params: IReqTradeVolumeParams): Promise<IRes> => {
  return axiosInstance.get(api.client.tradeAum, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 分页获取结构化产品订单
 * @params {IReqStructuredOrdersParams}
 * @return {Promise}
 */
export const getCmsStructuredOrders = (params: IReqStructuredOrdersParams): Promise<IRes> => {
  return axiosInstance.get(api.client.structuredOrders, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取结构化产品交易量占比增幅等信息
 * @params {IReqStructuredAnalysisParams}
 * @return {Promise}
 */
export const getCmsStructuredAnalysis = (params: IReqStructuredAnalysisParams): Promise<IRes> => {
  return axiosInstance.get(api.client.structuredAnalysis, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取结构化产品列表
 * @params {IReqStructuredProductsParams}
 * @return {Promise}
 */
export const getCmsStructuredProducts = (params: IReqStructuredProductsParams): Promise<IRes> => {
  return axiosInstance.get(api.client.structuredProducts, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 按月统计的结构化理财产品持有人数
 * @params {IReqStructuredDataParams}
 * @return {Promise}
 */
export const getCmsStructuredSubscriber = (params: IReqStructuredDataParams): Promise<IRes> => {
  return axiosInstance.get(api.client.structuredSubscriber, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 按月统计的结构化理财产品事件数
 * @params {IReqStructuredDataParams}
 * @return {Promise}
 */
export const getCmsStructuredEvent = (params: IReqStructuredDataParams): Promise<IRes> => {
  return axiosInstance.get(api.client.structuredEvent, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 按月统计的结构化理财产品aum
 * @params {IReqStructuredDataParams}
 * @return {Promise}
 */
export const getCmsStructuredAum = (params: IReqStructuredDataParams): Promise<IRes> => {
  return axiosInstance.get(api.client.structuredAum, { params }).then((res) => {
    return res?.data;
  });
};

/* -------------------- end 客户代理商相关 -------------------- */

/* -------------------- 用户相关 --------------------*/
/**
 * @description: 注册
 * @params {IReqRegister}
 * @return {Promise}
 */
export const register = (params: IReqRegister): Promise<IRes> => {
  return axiosInstance
    .post(api.customer.register, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 登陆
 * @params {IReqLogin}
 * @return {Promise}
 */
export const login = (params: IReqLogin, headers?: any): Promise<IRes> => {
  return axiosInstance.post(api.customer.login, params, { headers }).then((res) => {
    const { user_id, user_email } = res?.data?.data ?? {};
    addHeapIdentify(user_id, user_email);
    return res?.data;
  });
};
/**
 * @description: 子账号登陆
 * @params {IReqLogin}
 * @return {Promise}
 */
export const subLogin = (params: IReqLogin, headers?: any): Promise<IRes> => {
  return axiosInstance.post(api.customer.subLogin, params, { headers }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 登出
 * @params
 * @return {Promise}
 */
export const logout = (): Promise<IRes> => {
  return axiosInstance
    .delete(api.customer.logout)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};
/**
 * @description: 子账号登出
 * @params
 * @return {Promise}
 */
export const subLogout = (): Promise<IRes> => {
  return axiosInstance
    .delete(api.customer.subLogout)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};
/**
 * @description: 代理商登出
 * @params
 * @return {Promise}
 */
export const agentLogout = (): Promise<IRes> => {
  return axiosInstance
    .delete(api.customer.agentLogout)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};
/**
 * @description: 管理员登出
 * @params
 * @return {Promise}
 */
export const managerLogout = (): Promise<IRes> => {
  return axiosInstance
    .delete(api.customer.managerLogout)

    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

/**
 * @description: 忘记密码重置密码
 * @params {IReqForgetResetPassword}
 * @return {Promise}
 */
export const forgetResetPassword = (params: IReqForgetResetPassword): Promise<IRes> => {
  return axiosInstance
    .post(api.customer.forgetResetPassword, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: sub忘记密码重置密码
 * @params {IReqForgetResetPassword}
 * @return {Promise}
 */
export const subForgetResetPassword = (params: IReqForgetResetPassword): Promise<IRes> => {
  return axiosInstance
    .post(api.customer.subForgetResetPassword, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 直接修改密码
 * @params {IReqResetPassword}
 * @return {Promise}
 */
export const resetPassword = (params: IReqResetPassword): Promise<IRes> => {
  return axiosInstance.post(api.customer.resetPassword, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: sub直接修改密码
 * @params {IReqResetPassword}
 * @return {Promise}
 */
export const subResetPassword = (params: IReqResetPassword): Promise<IRes> => {
  return axiosInstance.post(api.customer.subResetPassword, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 修改邮箱
 * @params {IReqChangeEmail}
 * @return {Promise}
 */
export const changeEmail = (params: IReqChangeEmail): Promise<IRes> => {
  return axiosInstance.post(api.customer.changeEmail, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: oss上传
 * @params {IUploadQuery}
 * @return {Promise}
 */
export const getOssData = async (params: IUploadQuery): Promise<IRes> => {
  return axiosInstance.post(api.customer.ossData, params).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 获取上传签名后地址
 * @params {IUploadSignUrl}
 * @return {Promise}
 */
export const getOssSignUrl = async (params: IUploadSignUrl): Promise<IRes> => {
  return axiosInstance.post(api.customer.signUploadUrl, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取用户配置
 * @params
 * @return {Promise}
 */
export const getConfigInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.config).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取用户配置
 * @params
 * @return {Promise}
 */
export const getCustomerConfigInfo = (): Promise<IRes> => {
  return axiosInstance.get(api.customer.customerConfig).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 上报设备信息
 * @params { IReqDeviceInfo }
 * @return { Promise }
 */
export const reportDeviceInfo = (params: IReqDeviceInfo): Promise<IRes> => {
  return axiosInstance
    .post(api.customer.device, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取登录验证渠道
 * @params {}
 * @return {Promise}
 */
export const fecthCustomerSetting = (params: IReqLoginChannel): Promise<IRes> => {
  return axiosInstance.get(api.customer.customerSetting, { params }).then((res) => res.data);
};

/* -------------------- end 用户相关 --------------------*/

/* --------------------  账务历史 -------------------- */

/**
 * @description: 获取账务历史信息
 * @params {IReqAccountHistory}
 * @return {Promise}
 */
export const accountHistory = (params: IReqAccountHistory): Promise<IRes> => {
  return axiosInstance
    .get(api.accountHistory.replace(/{currency}/, params.currency), { params })
    .then((res) => {
      return res?.data;
    });
};

/* -------------------- end 账务历史 --------------------*/

/* --------------------  审批相关 -------------------- */

/**
 * @description: 获取账务历史信息
 * @return {Promise}
 */
export const getApplicationList = (params: IReqApplicationList): Promise<IRes> => {
  return axiosInstance.get(api.flowable.applicationList, { params }).then((res) => {
    return res?.data;
  });
};

/* -------------------- end 审批相关 --------------------*/

/* -------------------- 通用 -------------------- */

/*
 * @description: 获取所有币种、名称、icon等
 * @params {}
 * @return {Promise}
 */
export const fetchCoinList = (): Promise<IRes> => {
  return axiosInstance.get(api.market.currency.coinList).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 验证邀请码
 * @params {IReqverifyInvitationCode}
 * @return {Promise}
 */
export const verifyInvitationCode = (params: IReqverifyInvitationCode): Promise<IRes> => {
  return axiosInstance.get(api.customer.verifyInvitationCode, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 发送邮箱验证码
 * @params {IReqSendEmailCode}
 * @return {Promise}
 */
export const sendEmailCode = (params: IReqSendEmailCode): Promise<IRes> => {
  return axiosInstance
    .get(api.customer.sendEmailCode.replace(/{email}/, params?.email ?? ""), { params })
    .then((res) => {
      return res?.data ?? null;
    });
};
/**
 * @description: 登陆后发送邮箱验证码
 * @params {IReqSendEmailCode}
 * @return {Promise}
 */
export const sendAuthEmailCode = (params: IReqSendEmailCode): Promise<IRes> => {
  return axiosInstance.get(api.customer.sendAuthEmailCode, { params }).then((res) => {
    return res?.data ?? null;
  });
};

/**
 * @description: 校验邮箱验证码
 * @params {IReqVerifyEmailCode}
 * @return {Promise}
 */
export const verifyEmailCode = (params: IReqVerifyEmailCode): Promise<IRes> => {
  return axiosInstance.post(api.customer.verifyEmailCode, params).then((res) => {
    return res?.data;
  });
};
/* -------------------- end 通用 --------------------*/

/* -------------------- 会员相关 --------------------*/
/**
 * 会员权益明细列表
 * @param params type 暂时只有一个类型 [TREASURE_INTEREST_PROMOTION]
 */
export const memberEquities = (params = "TREASURE_INTEREST_PROMOTION"): Promise<IRes> => {
  return axiosInstance.get(api.member.replace(/{equityType}/, params)).then((res) => {
    return res?.data;
  });
};

export const memberAUM = (params: IReqMemberAum): Promise<IRes> => {
  return axiosInstance.get(api.memberAum, { params }).then((res) => {
    return res?.data;
  });
};

/* -------------------- end 会员相关 --------------------*/

/* -------------------- 累计期权相关 --------------------*/
/**
 * @description: 累计期权产品underlying
 * @params
 * @return {Promise}
 */
export const getAccumulatorUnderlying = (): Promise<IAccumulatorUnderlyingRes> => {
  return axiosInstance.get(api.accumulator.underlying).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 累计期权理财产品列表
 * @params {IAccumulatorProductsReq}
 * @return {Promise}
 */
export const getAccumulatorProducts = (
  params: IAccumulatorProductsReq
): Promise<IAccumulatorProductsRes> => {
  return axiosInstance
    .get(
      api.accumulator.productList
        .replace(/{underlying}/, params.underlying)
        .replace(/{quote}/, params.quote)
    )
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 累计期权理财产品详情
 * @params {IAccumulatorProductDetailReq}
 * @return {Promise}
 */
export const getAccumulatorProductDetail = (
  params: IAccumulatorProductDetailReq
): Promise<IAccumulatorProductsRes> => {
  return axiosInstance
    .get(
      api.accumulator.productDetail
        .replace(/{underlying}/, params.underlying)
        .replace(/{quote}/, params.quote)
        .replace(/{id}/, params.id)
    )
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取累计期权订单详情
 * @params string
 * @return {Promise}
 */
export const getAccumulatorOrderDetail = (orderId: string): Promise<IRes> => {
  return axiosInstance
    .get(api.accumulator.orderDetail.replace(/{orderId}/, orderId))
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 累计期权下单
 * @params {IAccumulatorOrderReq}
 * @return {Promise}
 */
export const postAccumulatorOrder = (
  params: IAccumulatorOrderReq
): Promise<IAccumulatorOrderRes> => {
  return axiosInstance
    .post(api.accumulator.order, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 累计期权定制服务
 * @params {IAccumulatorCustomizedReq}
 * @return {Promise}
 */
export const customizedService = (
  params: IAccumulatorCustomizedReq
): Promise<IAccumulatorCustomizedRes> => {
  return axiosInstance.post(api.accumulator.customizedService, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 钱包--结构化理财--累计期权账户
 * @params
 * @return {Promise}
 */
export const getWalletAccumulatorAccounts = (
  params: IWalletAccounts
): Promise<IAccumulatorAccountRes> => {
  return axiosInstance.get(api.wallet.accumulators, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 钱包--结构化理财--累计期权持仓详情
 * @params
 * @return {Promise}
 */
export const getWalletAccumulatorAccountsDetail = (
  params: IReqStructuredAccountList
): Promise<IRes> => {
  return axiosInstance.get(api.wallet.accumulatorAccountsDetail, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 累计期权订单列表
 * @params {IReqStructuredList}
 * @return {Promise}
 */
export const fetchAccumulatorHistory = (params: IReqStructuredList): Promise<IRes> => {
  return axiosInstance.get(api.accumulator.order, { params }).then((res) => {
    return res?.data;
  });
};
/* -------------------- end 累计期权相关 --------------------*/

/* -------------------- Onfido --------------------*/
export const getOnfidoToken = (): Promise<IRes> => {
  return axiosInstance
    .post(api.onfido.getToken, null, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

export const onfidoCheck = (): Promise<IRes> => {
  return axiosInstance
    .post(api.onfido.check, null, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/* -------------------- Onfido  end --------------------*/

/* -------------------- 雪球相关 --------------------*/
/**
 * @description: 雪球产品underlying
 * @params
 * @return {Promise}
 */
export const getSnowballUnderlying = (): Promise<ISnowballUnderlyingRes> => {
  return axiosInstance.get(api.snowball.underlying).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 雪球理财产品列表
 * @params {ISnowballProductsReq}
 * @return {Promise}
 */
export const getSnowballProducts = (
  params: ISnowballProductsReq
): Promise<ISnowballProductsRes> => {
  return axiosInstance
    .get(
      api.snowball.productList
        .replace(/{underlying}/, params.underlying)
        .replace(/{quote}/, params.quote)
    )
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 雪球理财产品详情
 * @params {ISnowballProductDetailReq}
 * @return {Promise}
 */
export const getSnowballProductDetail = (
  params: ISnowballProductDetailReq
): Promise<ISnowballProductsRes> => {
  return axiosInstance
    .get(
      api.snowball.productDetail
        .replace(/{underlying}/, params.underlying)
        .replace(/{quote}/, params.quote)
        .replace(/{id}/, params.id)
    )
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取雪球订单详情
 * @params string
 * @return {Promise}
 */
export const getSnowballOrderDetail = (orderId: string): Promise<IRes> => {
  return axiosInstance.get(api.snowball.orderDetail.replace(/{orderId}/, orderId)).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 雪球下单
 * @params {ISnowballOrderReq}
 * @return {Promise}
 */
export const postSnowballOrder = (params: ISnowballOrderReq): Promise<ISnowballOrderRes> => {
  return axiosInstance
    .post(api.snowball.order, params, {
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 雪球定制服务
 * @params {ISnowballCustomizedReq}
 * @return {Promise}
 */
export const snowballCustomizedService = (
  params: ISnowballCustomizedReq
): Promise<ISnowballCustomizedRes> => {
  return axiosInstance.post(api.snowball.customizedService, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 钱包--结构化理财--雪球账户
 * @params
 * @return {Promise}
 */
export const getWalletSnowballAccounts = (
  params: IWalletAccounts
): Promise<ISnowballAccountRes> => {
  return axiosInstance.get(api.wallet.snowball, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 钱包--结构化理财--雪球持仓详情
 * @params
 * @return {Promise}
 */
export const getWalletSnowballAccountsDetail = (
  params: IReqStructuredAccountList
): Promise<IRes> => {
  return axiosInstance.get(api.wallet.snowballAccountsDetail, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 雪球订单列表
 * @params {IReqStructuredList}
 * @return {Promise}
 */
export const fetchSnowballHistory = (params: IReqStructuredList): Promise<IRes> => {
  return axiosInstance.get(api.snowball.order, { params }).then((res) => {
    return res?.data;
  });
};
/* -------------------- end 雪球相关 --------------------*/

/**
 * @description: Events活动列表
 * @params {IEventsListReq}
 * @return {Promise}
 */
export const fetchEventsList = (params: IEventsListReq): Promise<IRes> => {
  return axiosInstance.get(api.events.list, { params }).then((res) => {
    return res?.data;
  });
};
/**
 * @description: Events详情
 * @params {TEventsDetailReq}
 * @return {Promise}
 */
export const getEventsDetail = (params: TEventsDetailReq, headers?: ISSRHeaders): Promise<IRes> => {
  return axiosInstance
    .get(api.events.detail.replace(/{id}/, params.id), { headers })
    .then((res) => {
      return res?.data;
    });
};
/**
 * @description: 报名活动
 * @params {IEventsListReq}
 * @return {Promise}
 */
export const bookEvents = (params: TRegisterEventsReq): Promise<IRes> => {
  return axiosInstance.put(api.events.book, params, {}).then((res) => {
    return res?.data;
  });
};
/**
 * @description: 取消报名
 * @params {IEventsListReq}
 * @return {Promise}
 */
export const cancelBookEvents = (params: TCancelEventsReq): Promise<IRes> => {
  return axiosInstance.post(api.events.cancelBook, params, {}).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取报名状态
 * @return {Promise}
 */
export const getConferenceBookStatus = (params: { conferenceId: string }): Promise<IRes> => {
  return axiosInstance.get(api.events.book, { params }).then((res) => {
    return res?.data;
  });
};
/* -------------------- end events --------------------*/

/* -------------------- start fund --------------------*/
/**
 * @description: fund账户
 * @params
 * @return {Promise}
 */
export const getWalletFundsAccounts = (params: IWalletAccounts): Promise<IRes> => {
  return axiosInstance.get(api.wallet.fund, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取代理商客户钱包fund表格信息
 * @params {IReqClientCustomerWalletList}
 * @return {Promise}
 */
export const getAgentCustomerFund = (params: IReqClientCustomerWalletList): Promise<IRes> => {
  return axiosInstance.get(api.client.fundAccount, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取近期fund某一产品的申购记录
 * @params {IReqClientSubscriptions}
 * @return {Promise}
 */
export const getCmsFundSubscriptions = (params: IReqClientSubscriptions): Promise<IRes> => {
  return axiosInstance.get(api.client.fundSubscriptions, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: fund申购
 * @params {IReqVentureApply}
 * @return {Promise}
 */
export const subscribeFund = (params: IReqVentureApply): Promise<IRes> => {
  return axiosInstance.post(api.fund.fundApply, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: fund赎回
 * @params {IReqVentureRedeem}
 * @return {Promise}
 */
export const redeemFund = (params: IReqVentureRedeem): Promise<IRes> => {
  return axiosInstance.post(api.fund.fundRedeem, params).then((res) => {
    return res?.data;
  });
};

/**
 * @description: fund列表
 * @params {IReqVentureList}
 * @return {Promise}
 */
export const getFundList = (params: IReqVentureList): Promise<IRes> => {
  return axiosInstance.get(api.fund.fundList, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: fund详情
 * @params {IReqVentureDetail}
 * @return {Promise}
 */
export const getFundDetail = (params: IReqFundDetail, headers?: ISSRHeaders): Promise<IRes> => {
  return axiosInstance
    .get(api.fund.fundDetail.replace(/{fundId}/, params.fundId), { headers })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: fund申赎历史列表
 * @params {IReqVentureList}
 * @return {Promise}
 */
export const getFundOrderList = (params: IReqVentureList): Promise<IRes> => {
  return axiosInstance.get(api.fund.fundOrderList, { params }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 查询指定基金产品已提交的赎回意向总金额
 * @params productId: string
 * @return {Promise}
 */
export const getFundIntentionAmount = (productId: string): Promise<IRes> => {
  return axiosInstance
    .get(api.fund.fundIntentionAmount.replace(/{product-id}/, productId), {
      params: { productId }
    })
    .then((res) => {
      return res?.data;
    });
};
/* -------------------- end fund --------------------*/
